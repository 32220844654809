// import { IconPhone } from "@tabler/icons-react";
// import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Testimonials from "../components/Testimonials";
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

function TestimonialsPage() {
  return (
    <>
      <section className="testimonial-page">
        <HeroPages name="Testimonials" />
        <Testimonials />
        <div className="book-banner">
          <div className="book-banner__overlay"></div>
          <div className="container">
          <div className="text-content">
            <h2 style={{color :"white"}}>Book a car by getting in touch with us</h2>
            <span>
              <FormatQuoteIcon width={40} height={40} style={{marginTop:"-30px" ,fontSize:"70px"}} />
              <h3 style={{color :"white"}}>+971 - 563619373</h3>
            </span>
          </div>
        </div>
        </div>
      </section>
    </>
  );
}

export default TestimonialsPage;
