import { useEffect, useState } from "react";
import CarAudi from "../images/cars-big/audia1.jpg";
import CarGolf from "../images/cars-big/golf6.jpg";
import CarToyota from "../images/cars-big/toyotacamry.jpg";
import CarBmw from "../images/cars-big/bmw320.jpg";
import CarMercedes from "../images/cars-big/benz.jpg";
import CarPassat from "../images/cars-big/passatcc.jpg";
// import { IconCar, IconInfoCircleFilled, IconX } from "@tabler/icons-react";
// import { IconMapPinFilled } from "@tabler/icons-react";
// import { IconCalendarEvent } from "@tabler/icons-react";



// import CalendarMonthIcon from '@material-ui/icons/CalendarMonth';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import TimeToLeaveIcon from '@material-ui/icons/TimeToLeave';
// import StreamIcon from '@material-ui/icons/Stream';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import DateRangeIcon from '@material-ui/icons/DateRange';
// import MarkEmailUnreadIcon from '@material-ui/icons/MarkEmailUnread';

import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';

import swal from 'sweetalert';

function BookCar() {
  const [modal, setModal] = useState(false); //  class - active-modal

  // booking car
  const [carType, setCarType] = useState("");
  const [pickUp, setPickUp] = useState("");
  const [dropOff, setDropOff] = useState("");
  const [pickTime, setPickTime] = useState("");
  const [dropTime, setDropTime] = useState("");
  const [carImg, setCarImg] = useState("");

  // modal infos
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [ID, setID] = useState("");


  const [Product_Image_Upload , setProduct_Image_Upload] = useState("")

  
  // const [pickup1 , setpickup1] = useState("")
  // const [drop1 , setdrop1] = useState("")
  // const [pickup2 , setpickup2] = useState("")
  // const [drop2 , setdrop2] = useState("")
  // const [pickup3 , setpickup3] = useState("")
  // const [drop3 , setdrop3] = useState("")
  // const [pickup4 , setpickup4] = useState("")
  // const [drop4 , setdrop4] = useState("")
  const [pickup , setpickup] = useState("")
  const [drop , setdrop] = useState("")
  const [choosetime , setchoosetime] = useState("")
  const [pickupdate , setpickupdate] = useState("")
  const [dropdate , setdropdate] = useState("")
  // const [phone , setphone] = useState("")
  const [car , setcar] = useState("")
  // taking value of modal inputs
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleAge = (e) => {
    setAge(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCity = (e) => {
    setCity(e.target.value);
  };

  const handleZip = (e) => {
    setZipCode(e.target.value);
  };

  // open modal when all inputs are fulfilled
  const openModal = (e) => {
    e.preventDefault();
    const errorMsg = document.querySelector(".error-message");
    if (
      pickUp === "" ||
      dropOff === "" ||
      pickTime === "" ||
      dropTime === "" ||
      carType === ""
    ) {
      errorMsg.style.display = "flex";
    } else {
      setModal(!modal);
      const modalDiv = document.querySelector(".booking-modal");
      modalDiv.scroll(0, 0);
      errorMsg.style.display = "none";
    }
  };

  // disable page scroll when modal is displayed
  useEffect(() => {
    if (modal === true) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modal]);

  // confirm modal booking
  const confirmBooking = (e) => {
    e.preventDefault();
    setModal(!modal);
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "flex";
  };

  // taking value of booking inputs
  const handleCar = (e) => {
    setCarType(e.target.value);
    setCarImg(e.target.value);
  };

  const handlePick = (e) => {
    setPickUp(e.target.value);
  };

  const handleDrop = (e) => {
    setDropOff(e.target.value);
  };

  const handlePickTime = (e) => {
    setPickTime(e.target.value);
  };

  const handleDropTime = (e) => {
    setDropTime(e.target.value);
  };

  // based on value name show car img
  let imgUrl;
  switch (carImg) {
    case "Audi A1 S-Line":
      imgUrl = CarAudi;
      break;
    case "VW Golf 6":
      imgUrl = CarGolf;
      break;
    case "Toyota Camry":
      imgUrl = CarToyota;
      break;
    case "BMW 320 ModernLine":
      imgUrl = CarBmw;
      break;
    case "Mercedes-Benz GLK":
      imgUrl = CarMercedes;
      break;
    case "VW Passat CC":
      imgUrl = CarPassat;
      break;
    default:
      imgUrl = "";
  }

  // hide message
  const hideMessage = () => {
    const doneMsg = document.querySelector(".booking-done");
    doneMsg.style.display = "none";
  };

    

  const SubmitDataMessage = (e) =>{
    e.preventDefault()
   
  
  if(car===""){
    swal("Select Car")
    return
  }
  if(pickup===""){
    swal("Select Pickup City")
    return
  }
  if(drop===""){
    swal("Select Drop Off City")
    return
  }
  const data = new FormData()
  data.append("file", Product_Image_Upload)
  data.append("upload_preset", "commerce")
  data.append("cloud_name", "freedeveloperss123")
  fetch("https://api.cloudinary.com/v1_1/freedeveloperss123/image/upload",{ 
      method : "POST",
      body : data , 
  })
  .then(res=>res.json())
  .then((res2)=>{
    console.log(res2)
    // this.setState({ url : res2.url})
    // setUrl(res2.url)
    // if(res2.url !== ""){
                        console.log(res2)
                                fetch("https://pappu-prado-main-back.vercel.app/createbooking1",{
                                            method: "POST",
                                            headers :  {
                                                "Content-Type" : "application/json" , 
                                            } ,
                                            body : JSON.stringify({
                                              pickup ,  
                                              drop ,
                                              choosetime ,
                                              pickupdate , 
                                              dropdate,
                                              phone ,
                                              car ,
                                              email ,
                                              // Product_Image_Upload : ID,
                                              Product_Image_Upload : res2.url ? res2.url : "",
                                            })
                                        })
                                        .then(res11=>res11.json())
                                        .then((res12)=>{ 
                                            if(res12.Error){
                                              this.setState({displayNone : true})
  
                                            swal(res12.Error)
                                            setTimeout(()=>{

                                              window.location.replace(`http://wa.me/923182333057?text=Hi I need a booking on this ${car}. Pick Up date is ${pickupdate} .  Pick Up From ${pickup} . `);
                                            },1500)
                                        }
                                        else{
                                                swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
                                                this.setState({
                                                  // Product_Catagories: "",
                                                  // Product_Image_Upload : "",
                                                  url : "",
                                                  
                                            })
                                            setcar("")
                                            // setcar("")
                                            setpickup("")
                                            setdrop("")
                                            setPhone("")
                                            setEmail("")
                                            this.setState({displayNone : true})
                                            setTimeout(()=>{

                                              window.location.replace(`http://wa.me/923182333057?text=Hi I need a booking on this ${car}. Pick Up date is ${pickupdate} .  Pick Up From ${pickup} . `);
                                            },1500)

                                          }
                                    })
                                    .catch(err=>{
                                      swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
                                      setcar("")
                                      // setcar("")
                                      setpickup("")
                                      setdrop("")
                                      setPhone("")
                                      setEmail("")
  
                                        }) 
                                        .catch((err)=>console.log(err))
                                     
                                      })   
                                      setTimeout(()=>{

                                        window.location.replace(`http://wa.me/923182333057?text=Hi I need a booking on this ${car}. Pick Up date is ${pickupdate} .  Pick Up From ${pickup} . `);
                                      },1500)
            }  
  
  return (
    <>
      <section id="booking-section" className="book-section">
        {/* <div
          onClick={openModal}
          className={`modal-overlay ${modal ? "active-modal" : ""}`}
        ></div> */}

        <div className="container">
          <div className="book-content">
            <div className="book-content__box">
              <h2>Book a car</h2>

              <p className="error-message">
                All fields required! <Brightness7Icon width={20} height={20} />
              </p>

              <p className="booking-done">
                Check your email to confirm an order.{" "}
                <Brightness7Icon width={20} height={20} onClick={hideMessage} />
              </p>

              <form className="box-form" onSubmit={(e)=>SubmitDataMessage(e)}>
                <div className="box-form__car-type" style={{width: "92%"}}>
                  <label>
                    <TimeToLeaveIcon className="input-icon"  style={{fontSize: "25px"}}/> &nbsp; Select Your Car
                    Type <b>*</b>
                  </label>
                  <select  value={car} onChange={(e)=>setcar(e.target.value)} required>
                    <option>Select your car type</option>
                    <option value="Saloon Coaster">Saloon Coaster</option>
                      <option value="GRAND CABIN">GRAND CABIN</option>
                      <option value="HONDA BRV">HONDA BRV</option>
                      <option value="Coaster 4c Salon">Coaster 4c Salon</option>
                      <option value="Coaster 5c Salon">Coaster 5c Salon</option>
                      <option value="Grand Cabin 224">Grand Cabin 224</option>
                      <option value="Changan karvaan 7 seater">Changan karvaan 7 seater</option>
                      <option value="Apv 7 seater">Apv 7 seater</option>
                      <option value="Honda BRV">Honda BRV</option>
                      <option value="Voxy 7 seater">Voxy 7 seater</option>
                      <option value="Land Cruiser Limousine">Land Cruiser Limousine</option>
                      <option value="Mercedes C Class">Mercedes C Class</option>
                      <option value="Audi A6">Audi A6</option>
                      <option value="Audi A5">Audi A5</option>
                      <option value="Audi A4">Audi A4</option>
                      <option value="V8 Land cruiser">V8 Land cruiser</option>
                      <option value="Toyota Revo">Toyota Revo</option>
                      <option value="Prado">Prado</option>
                      <option value="Fortuner">Fortuner</option>
                      <option value="Civic 2021">Civic 2021</option>
                      <option value="TOYOTA Grande X">TOYOTA Grande X</option>
                      <option value="Altis X Corolla 1.6">Altis X Corolla 1.6</option>
                      <option value="TOYOTA Corolla Gli">TOYOTA Corolla Gli</option>
                      <option value="TOYOTA COROLLA">TOYOTA COROLLA</option>
                  </select>
                </div>

                <div className="box-form__car-type" style={{width: "92%"}}>
                  <label>
                    <EditLocationIcon className="input-icon"  style={{fontSize: "25px"}}/> &nbsp; Pick-up{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{padding: ".5rem 1.3rem",height: "43px",width: "100%"}}
                    id="text"
                    value={pickup} onChange={(e)=>setpickup(e.target.value)} required
                    type="tel"
                    placeholder="Enter Your Pick up"

                  ></input>
                  {/* <select   value={pickup} onChange={(e)=>setpickup(e.target.value)} required>
                  <option value="Downtown Dubai">Downtown Dubai</option>
                  <option value="Dubai Marina">Dubai Marina</option>
                  <option value="Dubai Hills">Dubai Hills</option>
                  <option value="Jumeirah Beach Residence (JBR)">Jumeirah Beach Residence (JBR)</option>
                  <option value="Palm Jumeirah">Palm Jumeirah</option>
                  <option value="Jumeirah Lakes Towers (JLT)">Jumeirah Lakes Towers (JLT)</option>
                  <option value="Emirates Hills">Emirates Hills</option>
                  <option value="Arabian Ranches 1">Arabian Ranches 1</option>
                  <option value="Arabian Ranches 2">Arabian Ranches 2</option>
                  <option value="Arabian Ranches 3">Arabian Ranches 3</option>
                  <option value="Business Bay">Business Bay</option>
                  <option value="Dubai International Financial Centre (DIFC)">Dubai International Financial Centre (DIFC)</option>
                  <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                  <option value="Dubai Sports City">Dubai Sports City</option>
                  <option value="Dubai Investment Park (DIP)">Dubai Investment Park (DIP)</option>
                  <option value="Al Barsha">Al Barsha</option>
                  <option value="Al Nahda">Al Nahda</option>
                  <option value="Al Quoz">Al Quoz</option>
                  <option value="Karama">Karama</option>
                  <option value="Deira">Deira</option>
                  <option value="Bur Dubai">Bur Dubai</option>
                  <option value="Al Barsha South">Al Barsha South</option>
                  <option value="Al Satwa">Al Satwa</option>
                  <option value="Al Mankhool">Al Mankhool</option>
                  <option value="Al Wasl">Al Wasl</option>
                  <option value="Al Rashidiya">Al Rashidiya</option>
                  <option value="Al Rigga">Al Rigga</option>
                  <option value="Al Warqa'a">Al Warqa'a</option>
                  <option value="Dubailand">Dubailand</option>


                  </select> */}
                  {/* <input
                  style={{height: "43px",width: "100%"}}
                    id="picktime"
                    required

                    placeholder="Enter Your Pick Up Location"

                    value={pickup} onChange={(e)=>setpickup(e.target.value)}
                                        type="text"
                  ></input> */}
                  {/* <select  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option>Select pick up location</option>
                    <option>Belgrade</option>
                    <option>Novi Sad</option>
                    <option>Nis</option>
                    <option>Kragujevac</option>
                    <option>Subotica</option>
                  </select> */}
                </div>

                <div className="box-form__car-type" style={{width: "92%"}}>
                  <label>
                    <EditLocationIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Drop-off{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{padding: ".5rem 1.3rem",height: "43px",width: "100%"}}
                    id="text"
                    value={drop} onChange={(e)=>setdrop(e.target.value)} required
                    type="tel"
                    placeholder="Enter Your Drop off"

                  ></input>
                  {/* <select  value={drop} onChange={(e)=>setdrop(e.target.value)} required>
                  <option value="Downtown Dubai">Downtown Dubai</option>
                  <option value="Dubai Marina">Dubai Marina</option>
                  <option value="Dubai Hills">Dubai Hills</option>

                  <option value="Jumeirah Beach Residence (JBR)">Jumeirah Beach Residence (JBR)</option>
                  <option value="Palm Jumeirah">Palm Jumeirah</option>
                  <option value="Jumeirah Lakes Towers (JLT)">Jumeirah Lakes Towers (JLT)</option>
                  <option value="Emirates Hills">Emirates Hills</option>
                  <option value="Arabian Ranches 1">Arabian Ranches 1</option>
                  <option value="Arabian Ranches 2">Arabian Ranches 2</option>
                  <option value="Arabian Ranches 3">Arabian Ranches 3</option>
                  <option value="Business Bay">Business Bay</option>
                  <option value="Dubai International Financial Centre (DIFC)">Dubai International Financial Centre (DIFC)</option>
                  <option value="Dubai Silicon Oasis">Dubai Silicon Oasis</option>
                  <option value="Dubai Sports City">Dubai Sports City</option>
                  <option value="Dubai Investment Park (DIP)">Dubai Investment Park (DIP)</option>
                  <option value="Al Barsha">Al Barsha</option>
                  <option value="Al Nahda">Al Nahda</option>
                  <option value="Al Quoz">Al Quoz</option>
                  <option value="Karama">Karama</option>
                  <option value="Deira">Deira</option>
                  <option value="Bur Dubai">Bur Dubai</option>
                  <option value="Al Barsha South">Al Barsha South</option>
                  <option value="Al Satwa">Al Satwa</option>
                  <option value="Al Mankhool">Al Mankhool</option>
                  <option value="Al Wasl">Al Wasl</option>
                  <option value="Al Rashidiya">Al Rashidiya</option>
                  <option value="Al Rigga">Al Rigga</option>
                  <option value="Al Warqa'a">Al Warqa'a</option>
                  <option value="Dubailand">Dubailand</option>


                  </select> */}
                  {/* <input
                  style={{height: "43px",width: "100%"}}
                    id="picktime"
                    required

                     value={drop} onChange={(e)=>setdrop(e.target.value)}
                    type="text"
                    placeholder="Enter YOur Drop Off Location"
                  ></input> */}
                  {/* <select  style={{width: "100%"}}>
                    <option>Select drop off location</option>
                    <option>Novi Sad</option>
                    <option>Belgrade</option>
                    <option>Nis</option>
                    <option>Kragujevac</option>
                    <option>Subotica</option>
                  </select> */}
                </div>

                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="picktime">
                    <DateRangeIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Pick-up{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{height: "43px",width: "100%"}}
                    id="picktime"
                    required

                    value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}
                    type="date"
                  ></input>
                </div>

                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                    <DateRangeIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Drop-of{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{height: "43px",width: "100%"}}
                    id="droptime"
                    required

                    value={dropdate} onChange={(e)=>setdropdate(e.target.value)}
                    type="date"
                  ></input>
                </div>
                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                    <PhoneCallbackIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Phone No.{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{padding: ".5rem 1.3rem",height: "43px",width: "100%"}}
                    id="droptime"
                    required
                    value={phone} onChange={(e)=>setPhone(e.target.value)}
                    type="tel"
                    placeholder="Enter Your Mobile Number"

                  ></input>
                </div>
                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                  <i className="icon-envelope"   style={{fontSize: "25px",color:"black"}}/>

                    {/* <MarkEmailUnreadIcon className="input-icon"   style={{fontSize: "25px"}}/> */}
                     &nbsp; Email.{" "}
                    <b>*</b>
                  </label>
                  <input
                  style={{padding: ".5rem 1.3rem",height: "43px",width: "100%"}}
                    id="email"
                    required
                    value={email} onChange={(e)=>setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter Your Email"

                  ></input>
                </div>
                <div className="box-form__car-time" style={{width: "92%"}}>
                  <label htmlFor="droptime">
                    <DateRangeIcon className="input-icon"   style={{fontSize: "25px"}}/> &nbsp; Id Card{" "}
                    <b>( Optional )</b>
                  </label>
                  <input
                                    style={{height: "43px",padding: ".5rem 1.3rem",width: "100%"}}

                    id="droptime"
                    onChange={(e)=>setProduct_Image_Upload(e.target.files[0])}                     type="file"
                  ></input>
                </div>

                <button type="submit"  style={{width: "92%"}}>
                  Book Now
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* modal ------------------------------------ */}

      {/* <div className={`booking-modal ${modal ? "active-modal" : ""}`}>
        <div className="booking-modal__title">
          <h2>Complete Reservation</h2>
          <Brightness7Icon onClick={openModal} />
        </div>
        <div className="booking-modal__message">
          <h4>
            <IconInfoCircleFilled /> Upon completing this reservation enquiry,
            you will receive:
          </h4>
          <p>
            Your rental voucher to produce on arrival at the rental desk and a
            toll-free customer support number.
          </p>
        </div>
        <div className="booking-modal__car-info">
          <div className="dates-div">
            <div className="booking-modal__car-info__dates">
              <h5>Location & Date</h5>
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Pick-Up Date & Time</h6>
                  <p>
                    {pickTime} /{" "}
                    <input
                    style={{height: "43px"}} type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Drop-Off Date & Time</h6>
                  <p>
                    {dropTime} /{" "}
                    <input
                    style={{height: "43px"}} type="time" className="input-time"></input>
                  </p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Pick-Up Location</h6>
                  <p>{pickUp}</p>
                </div>
              </span>
            </div>

            <div className="booking-modal__car-info__dates">
              <span>
                <EditLocationIcon />
                <div>
                  <h6>Drop-Off Location</h6>
                  <p>{dropOff}</p>
                </div>
              </span>
            </div>
          </div>
          <div className="booking-modal__car-info__model">
            <h5>
              <span>Car -</span> {carType}
            </h5>
            {imgUrl && <img src={imgUrl} alt="car_img" />}
          </div>
        </div> */}


        {/* <div className="booking-modal__person-info">
          <h4>Personal Information</h4>
          <form className="info-form">
            <div className="info-form__2col">
              <span>
                <label>
                  First Name <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={name}
                  onChange={handleName}
                  type="text"
                  placeholder="Enter your first name"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Last Name <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={lastName}
                  onChange={handleLastName}
                  type="text"
                  placeholder="Enter your last name"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>

              <span>
                <label>
                  Phone Number <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={phone}
                  onChange={handlePhone}
                  type="tel"
                  placeholder="Enter your phone number"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Age <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={age}
                  onChange={handleAge}
                  type="number"
                  placeholder="18"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__1col">
              <span>
                <label>
                  Email <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={email}
                  onChange={handleEmail}
                  type="email"
                  placeholder="Enter your email address"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Address <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={address}
                  onChange={handleAddress}
                  type="text"
                  placeholder="Enter your street address"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <div className="info-form__2col">
              <span>
                <label>
                  City <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={city}
                  onChange={handleCity}
                  type="text"
                  placeholder="Enter your city"
                ></input>
                <p className="error-modal">This field is required.</p>
              </span>

              <span>
                <label>
                  Zip Code <b>*</b>
                </label>
                <input
                style={{height: "43px"}}
                  value={zipcode}
                  onChange={handleZip}
                  type="text"
                  placeholder="Enter your zip code"
                ></input>
                <p className="error-modal ">This field is required.</p>
              </span>
            </div>

            <span className="info-form__checkbox">
              <input
              style={{height: "43px"}} type="checkbox"></input>
              <p>Please send me latest news and updates</p>
            </span>

            <div className="reserve-button">
              <button onClick={confirmBooking}>Reserve Now</button>
            </div>
          </form>
        </div>
      </div> */}
    </>
  );
}

export default BookCar;
