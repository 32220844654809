import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"

import swal from 'sweetalert';



import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import a1 from '../assets/images/a1.jpeg';
import a2 from '../assets/images/a2.jpeg';
import a3 from '../assets/images/a3.jpeg';
import a4 from '../assets/images/a4.jpeg';
import a5 from '../assets/images/a6.jpeg';
import s1 from '../assets/images/s1.png';
import s2 from '../assets/images/s2.png';
import s3 from '../assets/images/s3.png';
import s4 from '../assets/images/s4.png';
import s5 from '../assets/images/s5.png';
import s6 from '../assets/images/s6.png';
import s7 from '../assets/images/s7.png';
import s8 from '../assets/images/s8.png';

const iconBox = () =>{
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items:6,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        }
      };
    
  
    return (

        <Carousel
  swipeable={false}
  draggable={true}
  showDots={false}
  arrows={false}
  responsive={responsive}
  infinite={true}
  minimumTouchDrag={1}
  autoPlay = {true}
  transitionDuration={3000}
//   autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={4000}
  keyBoardControl={true}
  customTransition="all 2s ease-out"
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
//   deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
 


                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={a1} alt="" style={{width:"80px",height:"80px"}}/>
                      </span>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={a2} alt="" style={{width:"80px",height:"90px"}}/>
                      </span>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={a3} alt="" style={{width:"80px",height:"80px"}}/>
                      </span>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={a4} alt="" style={{width:"85px",height:"80px"}}/>
                      </span>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={a5} alt="" style={{width:"90px",height:"80px"}}/>
                      </span>
                    </div>
                    {/* <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={s6} alt="" style={{width:"75px"}}/>
                      </span>
                    </div> */}
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={s3} alt="" style={{width:"80px",height:"80px"}}/>
                      </span>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="mb-0">
                        <img src={s4} alt="" style={{width:"80px",height:"80px"}}/>
                      </span>
                    </div>
                    {/* <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto kkkkkppokpo" style={{justifyContent:"center"}}>
                      <span className="text-dark mb-0">
                        <i className="icon-rotate-left py-2 pt-0" style={{color : "#d60620"}} />
                      </span>
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Return &amp; Refund</h3>
                        <p className="font-weight-light" style={{color : "black"}}>Free 100% money back guarantee</p>
                      </div>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                      <span className="text-dark mb-0">
                        <i className="icon-life-ring py-2 pt-0" style={{color : "#d60620"}} />
                      </span>
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>Quality Support</h3>
                        <p className="font-weight-light" style={{color : "black"}}>Alway online feedback 24/7</p>
                      </div>
                    </div>
                    <div className="icon-box mb-0 d-md-flex align-items-md-center text-center text-md-left mx-md-0 mx-auto">
                      <span className="text-dark mb-0">
                        <i className="icon-envelope py-2 pt-0" style={{color : "#d60620"}} />
                      </span>
                      <div className="icon-box-content">
                        <h3 className="icon-box-title font-size-normal mb-0 font-weight-bold text-uppercase" style={{color : "black"}}>JOIN OUR NEWSLETTER</h3>
                        <p className="font-weight-light" style={{color : "black"}}>10% off by subscribing to our newsletter</p>
                 
                  </div> 
                    </div> */}    
          
</Carousel>

    )
}

export default iconBox