import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
// import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

import WhatshotIcon from '@material-ui/icons/Whatshot';

// import Hero from "../components/Hero";
import BookCar from "../components/BookCar";
import Abou from "./Abou";
// import Testimonials from "../components/Testimonials";
// import PickCar from "../components/PickCar";
// import ChooseUs from "../components/ChooseUs";
// import Faq from "../components/Faq";

import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// import KIT1 from '../assets/images/KIT.jpg';


// import Tourism from '../assets/images/Tourism.svg';
// import slid2 from '../assets/images/slid2.webp';
// import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
// import logo from '../assets/images/logo-kar.png';
// import ab2 from '../assets/images/aboutimage.png';
// import about from '../assets/images/about1.png';


// import as1 from '../assets/images/as4.jpg';
import as4 from '../assets/images/Frame 2.png';
// import as2 from '../assets/images/as5.jpg';
import as3 from '../assets/images/as3.jpg';


// import ch1 from '../asset1/images/demos/demo-26/banners/banner-5.jpg';
// import ch2 from '../asset1/images/demos/demo-26/banners/banner-6.jpg';
// import ch3 from '../asset1/images/demos/demo-26/bg-1.jpg';




// import ch34 from '../assets/images/Unt.png';
// import ch343 from '../assets/images/Unt1.jpg';
import ch344 from '../assets/images/man-with-car.png';
import ch3444 from '../assets/images/support.png';
import ch34444 from '../assets/images/car-insurance.png';
// import ch344444 from '../assets/images/dollar-bill.png';
import ch3444444 from '../assets/images/calendar.png';
import ch34444444 from '../assets/images/parking-area.png';
// import ch344444444 from '../assets/images/KIT3.jpg';
import Toyo from '../assets/images/Toyota-Corolla_20230829_201645_0000-2-1024x767.png';
import Toyo2 from '../assets/images/Toyota Land Cruiser V8.png';
import Toyo3 from '../assets/images/Prado TX.png';
// import Toyo4 from '../assets/images/toyota-coaster-bus-car-rental-coaster-bus-3f48fa740baeaaeb202cf19c12b8bc31.png';
// import Toyo51 from '../assets/images/moving-black-car-road.jpg';
// import Toyo511 from '../assets/images/center.png';
// import ch4 from '../asset1/images/demos/demo-14/slider/slide-2.jpg';
// import ch5 from '../asset1/images/demos/demo-26/banners/banner-3.jpg';
// import ch6 from '../asset1/images/demos/demo-26/banners/banner-4.jpg';
import IconBox from './iconBox';
import Carousel1 from './Carosel';
// import Carousel2 from './Carosel1';
// import Carousel3 from './Carosel2';
// import Carousel4 from './Carosel3';
// import Carousel5 from './Carosel4';
// import Carousel6 from './Carosel5';
// import Carousel7 from './Carosel6';
// import Carousel8 from './Carosel7';
// import Ico from './iconBox';
// import Cate from './Cate';

// import "react-multi-carousel/lib/styles.css";


// import ch6 from '../asset1/images/contact-header-bg.jpg';
// import ch1 from '../assets/images/pd1-724x1024.jpg';
// import ch2 from '../assets/images/pd2-724x1024.jpg';
// import ch3 from '../assets/images/pd3-724x1024.jpg';
// import ch4 from '../assets/images/pd4-724x1024.jpg';
// import ch5 from '../assets/images/pd5-724x1024.jpg';




const Home = ( props ) => {
   
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
//     const [options , setoptions] = useState( {
//     loop: true,
//     margin: 10,
//     nav: true,
//     responsive: {
//         0: {
//             items: 1,
//         },
//         600: {
//             items: 3,
//         },
//         1000: {
//             items: 4,
//         },
//     },
// })
    // const [Categories , setCategories] = useState([])
    // const [Categories1 , setCategories1] = useState([])
    // const [Homo , setHomo] = useState([])
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    // const [SliderPhoto , setSliderPhoto] = useState([])
    // const [SliderPhoto8 , setSliderPhoto8] = useState([])
    // const [ArrivalPhoto , setArrivalPhoto] = useState([])
    // const [BannerPhoto , setBannerPhoto] = useState([])
    // const [MainCatogories , setMainCatogories] = useState([])
    // const [SubCategories , setSubCategories] = useState([])
    // const [SubClassCategories , setSubClassCategories] = useState([])
    // const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    // const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    // const [chk1 , setchk1] = useState(true)
    // const [chk2 , setchk2] = useState(true)
    // const [Cate1 , setCate1] = useState([])
    // const [Cate2 , setCate2] = useState([])


    const [CarBookPrice , setCarBookPrice] = useState([])
    
    // const [Ite1 , setIte1] = useState(1)
    // const [Ite2 , setIte2] = useState(1)
    
    // const [fg , s/etfg] = useState(true)




    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    // const [pickup , setpickup] = useState("")
    // const [drop , setdrop] = useState("")
    // const [choosetime , setchoosetime] = useState("")
    // const [pickupdate , setpickupdate] = useState("")
    // const [dropdate , setdropdate] = useState("")
    // const [phone , setphone] = useState("")
    // const [car , setcar] = useState("")
    // const [email , setemail] = useState("")
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
    

    useEffect(() =>{

        window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
 
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
    //   fetch("https://pak-dream-main-back.vercel.app/slider-photo",{
    //     method: "GET",
    //      headers :  {
    //      "Content-Type" : "application/json" , 
    //  } ,
    // })
    // .then(res=>res.json())
    // .then(res1=>{
    //     console.log(res1[0],res1);
    //     setSliderPhoto(res1)
    //   })
//         fetch("https://pappu-prado-main-back.vercel.app/slider-photo",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res=>res.json())
//         .then(res1=>{
//             console.log(res1[0],res1);
//             setSliderPhoto(res1)
//           })
//         fetch("https://pappu-prado-main-back.vercel.app/offer-photo",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res=>res.json())
//         .then(res1=>{
//             // console.log(res1[0]);
//             setSliderPhoto8(res1)
//           })
    
//         fetch("https://pappu-prado-main-back.vercel.app/all-CarBookPrice",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res2=>res2.json())
//         .then(res3=>{
//             console.log(res3);
//             setCarBookPrice(res3)
        
//         })
       

//         fetch("https://pappu-prado-main-back.vercel.app/CourseAllSubCategories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res2=>res2.json())
//         .then(res3=>{
//             let data = res3.sort((a, b) => {
//                 return a.sort - b.sort;
//             });
//             // console.log(data);
//             setSubClassCategories(data)


//             fetch("https://pappu-prado-main-back.vercel.app/AllCategories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res12=>res12.json())
//         .then(res13=>{
//           let a = []
//           console.log(res3,res13)
//           for (let i = 0; i < res13.length; i++) {
//           for (let j = 0; j < res3.length; j++) {
//             console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
//             if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
//               // a.push("1")
// console.log(i,j)
//               a[i] = "1"
//               break
//             }
//             else{
//               a[i] = "0"
//               // a.pusH(("0"))
//             }
            
//           }
//           }
//           console.log(a)
//           setCate2(a)
//         })
//         })
        // fetch("https://pappu-prado-main-back.vercel.app/MainCatogories",{
        //     method: "GET",
        //      headers :  {
        //      "Content-Type" : "application/json" , 
        //  } ,
        // })
        // .then(res2=>res2.json())
        // .then(res3=>{
        //     console.log(res3);
        //     // setMainCatogories(res3)
            

        //     let dat = []
        //     res3.map((res55,c)=>{
        //         dat.push(res55)
        //     })
        //     // console.log(SubClassCategories,asse)
        //     console.log(dat)
        //     let data = dat.sort((a, b) => {
        //         return a.sort - b.sort;
        //     });
        //     setMainCatogories(data)
            
        // })
//         fetch("https://pappu-prado-main-back.vercel.app/AllCategories",{
//           method: "GET",
//            headers :  {
//            "Content-Type" : "application/json" , 
//        } ,
//       })
//       .then(res2=>res2.json())
//       .then(res3=>{
//         let unique_values = res3
//         .map((item) => item.CategoriesName)
//         .filter(
//             (value, index, current_value) => current_value.indexOf(value) === index
//         );
//           // console.log(res3);
//           // const Data1 = res3.filter((res4,i)=>{
//           //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
//           //     return res4
//           // })
//           // console.log(Data1,res3)
//           setSubCategories(unique_values)
//           // setSubCategoriesname(res3)
//           fetch("https://pappu-prado-main-back.vercel.app/MainCatogories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res12=>res12.json())
//         .then(res13=>{

//           let a = []
//           console.log(res13)
//           for (let i = 0; i < res13.length; i++) {
//           for (let j = 0; j < res3.length; j++) {
//             // console.log(res3[j].MainCategories , res13[i].MainCategories)
//             if(res3[j].MainCategories === res13[i].MainCategories){
//               // a.push("1")
// // console.log(i,j)
//               a[i] = "1"
//               break
//             }
//             else{
//               a[i] = "0"
//               // a.pusH(("0"))
//             }
            
//           }
//           }
//           // console.log(a)
//           setCate1(a)
//         })
//       })




      //   localStorage.removeItem("SearchData")
      //   localStorage.removeItem("Data")
      //   localStorage.removeItem("CartPrice")
      //   localStorage.removeItem("CateProduct")

  
      //   var data1 =  JSON.parse(localStorage.getItem("Cart")) 
      //   fetch("https://pappu-prado-main-back.vercel.app/FAQ1",{
      //     method: "GET",
      //      headers :  {
      //      "Content-Type" : "application/json" , 
      //  } ,
      // })
      // .then(res3=>res3.json())
      // .then(res4=>{
      //   let dat1 = []
      //   res4.map((res55,c)=>{
      //       dat1.push(0)
      //   })
      //   setfvalue(dat1)
      //        setdata(res4)
      //        data.map((res,i)=>{
      //         data1[i] = res.text
      //        })

      //     console.log(res4)
      //   })
    
        fetch("https://pappu-prado-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    localStorage.setItem("Prod",JSON.stringify(res6))
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(pro.slice(0,8))
    setproduct1(pro1.slice(0,8))

    setdis(true)
   })
    
 },[])

 
   
    
    
 const setpickup11 = (e)=>{
  setpickup1(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
  if(drop1){
    let a = false
    for (let i = 0; i < CarBookPrice.length; i++) {

      if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
        a = true
        if(CarBookPrice[i].Price){
          document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car1").innerHTML ="No Any Package"
        }
      }
      
    }
    if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
  }

}
const setpickup21 = (e)=>{
  setpickup2(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
  if(drop2){
    let a = false

    for (let i = 0; i < CarBookPrice.length; i++) {

      if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
        a=  true

        if(CarBookPrice[i].Price){
          document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car2").innerHTML ="No Any Package"
        }
      }
      
      
    }
    if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
  }

}
const setpickup31 = (e)=>{
  setpickup3(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
  if(drop3){
    let a = false

    for (let i = 0; i < CarBookPrice.length; i++) {

      if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
        a=  true

        if(CarBookPrice[i].Price){
          document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car3").innerHTML ="No Any Package"
        }
      }
      
      
    }
    if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
  }

}
const setpickup41 = (e)=>{
  setpickup4(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
  if(drop1){
    let a = false

    for (let i = 0; i < CarBookPrice.length; i++) {

      if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
        a=  true

        if(CarBookPrice[i].Price){
          document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
          document.getElementById("car4").innerHTML ="No Any Package"
        }
      }
      
      
    }
    if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
  }

}
const setdrop11 = (e)=>{
  setdrop1(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
if(pickup1){
  let a = false
  for (let i = 0; i < CarBookPrice.length; i++) {
    if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
      a = true  
      if(CarBookPrice[i].Price> 1){
          console.log("gdfg",CarBookPrice[i].Price)
          document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
        }
        else{
        console.log("gdfg",CarBookPrice[i].Price)
        document.getElementById("car1").innerHTML ="No Any Package"
      }
    }
    
    }
    if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

  }
  console.log("gdfg")
}
const setdrop21 = (e)=>{
  setdrop2(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
  if(pickup2){
    let a = false

    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
        a=  true

        if(CarBookPrice[i].Price){
        document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
      }
      else{
        document.getElementById("car2").innerHTML ="No Any Package"
      }
    }
    
  }
  if(!a)  document.getElementById("car2").innerHTML ="No Any Package"
  }
}
const setdrop31 = (e)=>{
  setdrop3(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
  if(pickup3){
    let a = false

    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
        a=  true

        if(CarBookPrice[i].Price){
        document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
      }
      else{
        document.getElementById("car3").innerHTML ="No Any Package"
      }
    }
    
    }
    if(!a)  document.getElementById("car3").innerHTML ="No Any Package"

  }
}
const setdrop41 = (e)=>{
  setdrop4(e)
  if(couponShow)
  setcouponShow(false)
else setcouponShow(true)
  if(pickup4){
    let a = false

    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
      a=  true
        if(CarBookPrice[i].Price){
        document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
      }
      else{
        document.getElementById("car4").innerHTML ="No Any Package"
      }
    }
    
    }
    if(!a)  document.getElementById("car4").innerHTML ="No Any Package"

  }
}



const setCarPick1 = (e)=>{
  if(pickup1 && drop1){
    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla"){
    
        if(CarBookPrice[i].Price){
          localStorage.setItem("CarBookPric", JSON.stringify({
            Car : "Toyota Corolla", 
            Price :CarBookPrice[i].Price, 
            Pickup :pickup1,
            Drop : drop1
          }))
          swal("Booking Added in your Cart")
          props.history.push("/checkout2")
      }
      else{
        swal("No Package Avaiable")
      }
     
    }
    
    }

  }
  else{
    swal("Select PickUp & Drop Off City")
  }
}
const setCarPick2 = (e)=>{
  if(pickup2 && drop2){
    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Honda BRV"){
    
        if(CarBookPrice[i].Price){
          localStorage.setItem("CarBookPric", JSON.stringify({
            Car : "THonda BRV", 
            Price :CarBookPrice[i].Price, 
            Pickup :pickup2,
            Drop : drop2
          }))
          swal("Booking Added in your Cart")
          props.history.push("/checkout2")
      }
      else{
        swal("No Package Avaiable")
      }
     
    }
    
    }

  }
  else{
    swal("Select PickUp & Drop Off City")
  }
}
const setCarPick3 = (e)=>{
  if(pickup3 && drop3){
    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Grand Cabin"){
    
        if(CarBookPrice[i].Price){
          localStorage.setItem("CarBookPric", JSON.stringify({
            Car : "Grand Cabin", 
            Price :CarBookPrice[i].Price, 
            Pickup :pickup3,
            Drop : drop3
          }))
          swal("Booking Added in your Cart")
          props.history.push("/checkout2")
      }
      else{
        swal("No Package Avaiable")
      }
     
    }
    
    }

  }
  else{
    swal("Select PickUp & Drop Off City")
  }
}
const setCarPick4 = (e)=>{
  if(pickup4 && drop4){
    for (let i = 0; i < CarBookPrice.length; i++) {
      if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Saloon Coaster"){
    
        if(CarBookPrice[i].Price){
          localStorage.setItem("CarBookPric", JSON.stringify({
            Car : "Saloon Coaster", 
            Price :CarBookPrice[i].Price, 
            Pickup :pickup4,
            Drop : drop4
          }))
          swal("Booking Added in your Cart")
          props.history.push("/checkout2")
      }
      else{
        swal("No Package Avaiable")
      }
     
    }
    
    }

  }
  else{
    swal("Select PickUp & Drop Off City")
  }
}
    

// const SubmitDataMessage = (e) =>{
//   e.preventDefault()
 

// if(pickup===""){
//   swal("Enter Pickup City")
//   return
// }
// if(drop===""){
//   swal("Enter Drop City")
//   return
// }

//                               fetch("https://pappu-prado-main-back.vercel.app/createbooking1",{
//                                           method: "POST",
//                                           headers :  {
//                                               "Content-Type" : "application/json" , 
//                                           } ,
//                                           body : JSON.stringify({
//                                             pickup ,  
//                                             drop ,
//                                             choosetime ,
//                                             pickupdate , 
//                                             dropdate,
//                                             phone ,
//                                             car ,
//                                             email ,
//                                             Product_Image_Upload : "A",
//                                             // Product_Image_Upload : res2.url,
//                                           })
//                                       })
//                                       .then(res11=>res11.json())
//                                       .then((res12)=>{ 
//                                           if(res12.Error){
//                                             this.setState({displayNone : true})

//                                           swal(res12.Error)
//                                       }
//                                       else{
//                                               swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
//                                               this.setState({
//                                                 // Product_Catagories: "",
//                                                 // Product_Image_Upload : "",
//                                                 url : "",
//                                           })
//                                           this.setState({displayNone : true})

//                                       }
//                                   })
//                                   .catch(err=>{
//                                     swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");

//                                       // swal("There is An Error")                  
//                                       }) 
//                                   // }
//                                   // else{
//                                   //   swal("Successfully Upload your Product!");

//                                   //     swal("Upload Again")                  
//                                   // }
//               // })
//               // .catch(err=>swal("There is an Error"))

//           }  

    
//           const setpickup11 = (e)=>{
//             setpickup1(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop1){
//               let a = false
//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
//                   a = true
//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car1").innerHTML ="No Any Package"
//                   }
//                 }
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup21 = (e)=>{
//             setpickup2(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop2){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === " Toyota Land Cruiser V8"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car2").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup31 = (e)=>{
//             setpickup3(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop3){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car3").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setpickup41 = (e)=>{
//             setpickup4(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(drop1){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {

//                 if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                     document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                     document.getElementById("car4").innerHTML ="No Any Package"
//                   }
//                 }
                
                
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
//             }

//           }
//           const setdrop11 = (e)=>{
//             setdrop1(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//           if(pickup1){
//             let a = false
//             for (let i = 0; i < CarBookPrice.length; i++) {
//               if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
//                 a = true  
//                 if(CarBookPrice[i].Price> 1){
//                     console.log("gdfg",CarBookPrice[i].Price)
//                     document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
//                   }
//                   else{
//                   console.log("gdfg",CarBookPrice[i].Price)
//                   document.getElementById("car1").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

//             }
//             console.log("gdfg")
//           }
//           const setdrop21 = (e)=>{
//             setdrop2(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup2){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car2").innerHTML ="No Any Package"
//                 }
//               }
              
//             }
//             if(!a)  document.getElementById("car2").innerHTML ="No Any Package"
//             }
//           }
//           const setdrop31 = (e)=>{
//             setdrop3(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup3){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
//                   a=  true

//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car3").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car3").innerHTML ="No Any Package"

//             }
//           }
//           const setdrop41 = (e)=>{
//             setdrop4(e)
//             if(couponShow)
//             setcouponShow(false)
//           else setcouponShow(true)
//             if(pickup4){
//               let a = false

//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
//                 a=  true
//                   if(CarBookPrice[i].Price){
//                   document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
//                 }
//                 else{
//                   document.getElementById("car4").innerHTML ="No Any Package"
//                 }
//               }
              
//               }
//               if(!a)  document.getElementById("car4").innerHTML ="No Any Package"

//             }
//           }



//           const setCarPick1 = (e)=>{
//             if(pickup1 && drop1){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Toyota Corolla", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup1,
//                       Drop : drop1
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick2 = (e)=>{
//             if(pickup2 && drop2){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "THonda BRV", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup2,
//                       Drop : drop2
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick3 = (e)=>{
//             if(pickup3 && drop3){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Grand Cabin", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup3,
//                       Drop : drop3
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
//           const setCarPick4 = (e)=>{
//             if(pickup4 && drop4){
//               for (let i = 0; i < CarBookPrice.length; i++) {
//                 if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
              
//                   if(CarBookPrice[i].Price){
//                     localStorage.setItem("CarBookPric", JSON.stringify({
//                       Car : "Saloon Coaster", 
//                       Price :CarBookPrice[i].Price, 
//                       Pickup :pickup4,
//                       Drop : drop4
//                     }))
//                     swal("Booking Added in your Cart")
//                     props.history.push("/checkout2")
//                 }
//                 else{
//                   swal("No Package Avaiable")
//                 }
               
//               }
              
//               }

//             }
//             else{
//               swal("Select PickUp & Drop Off City")
//             }
//           }
        return (
            
          <div>




<div className="intro-section change-height-slider" style={{width:"98%",margin:"auto"}}>
                <div className="container">
                  <div className="row">
                  
                    <div className="col-xl-12 col-md-12 col-12 mb-md-0" style={{padding : "0px"}}>
                      <div className="intro-slider-container">
                    
<Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true} style={{height: "300px"}}>
  
  
{/*   
    <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "320px"}} >
    <img src={slider} alt="" className='change-slid' style={{height: "485px"}}/>
    
  </div> */}

  
     <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "700px"}} >
     <img src={as4} alt="" className='change-slid' style={{height: "700px"}}/>
   </div>
     <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "700px"}} >
     <img src={"https://assets.simpleviewinc.com/simpleview/image/upload/c_limit,q_75,w_1200/v1/crm/queenstownnz/rental_car_Deals_NZ_Ace_CDD0BF66-1AC8-4A17-980B252C9286B308_ee4aef10-ba4b-499a-bad23af5b90564b6.jpg"} alt="" className='change-slid' style={{height: "700px"}}/>
   </div>
     <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "700px"}} >
     <img src={"https://zouton.com/images/originals/blog/banner2_1591712362.png"} alt="" className='change-slid' style={{height: "700px"}}/>
   </div>
     {/* <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "700px"}} >
     <img src={as1} alt="" className='change-slid' style={{height: "700px"}}/>
   </div> */}

     {/* <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "700px"}} >
     <img src={as2} alt="" className='change-slid' style={{height: "700px"}}/>
   </div> */}

     {/* <div className="intro-slide bg-image d-flex align-items-center change-slid1"  style={{ height: "700px"}} >
     <img src={as3} alt="" className='change-slid' style={{height: "700px"}}/>
   </div> */}


  </Carousel>
 


                        </div>
                  
                    </div>
                   
                  </div>
                </div>
              </div>





{/* <Hero/> */}



























          <div className="page-wrapper">
            
            {/* End .header */}
            <main className="main" style={{maxHeight:  '100%' , overflowX :  "hidden"}}>






            <BookCar />


            <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Our Rental Fleet at Rent a Car in Karachi
</h3>

                <div>
            

<Carousel1/>

                       
                </div>  
              </div>

<br/>
<Abou/>










          




{/* 
              <div id="booking" class="section">
		<div class="section-center">
			<div class="container">
				<div class="row">
					<div class="booking-form">
						<form onSubmit={(e)=>SubmitDataMessage(e)}>
					
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option value="Choose PickUp">Choose PickUp</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF CITY</span>
                    <select class="form-control"  required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Choose Drop Off">Choose Drop Off</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                    <span class="select-arrow"></span>

                  </div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Choose Time</span>
										<input class="form-control" type="time" required placeholder="Choose Time"  value={choosetime} onChange={(e)=>setchoosetime(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Email</span>
										<input class="form-control" type="email" required placeholder="Enter Email"  value={email} onChange={(e)=>setemail(e.target.value)}/>
									</div>
								</div>
							
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PICK-UP DATE</span>
										<input class="form-control" type="date" required  value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">DROP-OFF DATE  </span>
										<input class="form-control" type="date" required  value={dropdate} onChange={(e)=>setdropdate(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">PHONE NUMBER
</span>
                    <input class="form-control" type="number" required placeholder="Enter PHONE NUMBER"  value={phone} onChange={(e)=>setphone(e.target.value)}/>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<span class="form-label">Car</span>
                    <select class="form-control" required="required" aria-required="true"  value={car} onChange={(e)=>setcar(e.target.value)}>
                      <option value="Saloon Coaster">Saloon Coaster</option>
                      <option value="GRAND CABIN">GRAND CABIN</option>
                      <option value="HONDA BRV">HONDA BRV</option>
                      <option value="Coaster 4c Salon">Coaster 4c Salon</option>
                      <option value="Coaster 5c Salon">Coaster 5c Salon</option>
                      <option value="Grand Cabin 224">Grand Cabin 224</option>
                      <option value="Changan karvaan 7 seater">Changan karvaan 7 seater</option>
                      <option value="Apv 7 seater">Apv 7 seater</option>
                      <option value="Honda BRV">Honda BRV</option>
                      <option value="Voxy 7 seater">Voxy 7 seater</option>
                      <option value="Land Cruiser Limousine">Land Cruiser Limousine</option>
                      <option value="Mercedes C Class">Mercedes C Class</option>
                      <option value="Audi A6">Audi A6</option>
                      <option value="Audi A5">Audi A5</option>
                      <option value="Audi A4">Audi A4</option>
                      <option value="V8 Land cruiser">V8 Land cruiser</option>
                      <option value="Toyota Revo">Toyota Revo</option>
                      <option value="Prado">Prado</option>
                      <option value="Fortuner">Fortuner</option>
                      <option value="Civic 2021">Civic 2021</option>
                      <option value="TOYOTA Grande X">TOYOTA Grande X</option>
                      <option value="Altis X Corolla 1.6">Altis X Corolla 1.6</option>
                      <option value="TOYOTA Corolla Gli">TOYOTA Corolla Gli</option>
                      <option value="TOYOTA COROLLA">TOYOTA COROLLA</option>
</select>
										<span class="select-arrow"></span>
									</div>
								</div>
							</div>
							<div class="row">
								<div class="col-md-3">
									<div class="form-btn">
										<button class="submit-btn">Book Now</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
           <br/>   */}
           {/* <br/>  
           <PickCar /> */}

























<div className='container' style={{background :  "white"}}>
<h3 className="title text-center font-weight-bold mt-3"> Our Transport all over Pakistan <br />
                <span style={{color : "#FE7448"}}>
                 One Way Drop Packages
                  </span> 
</h3>
        {/* <p class="mb-2 text-center" style={{color : "black",fontWeight:600}}>
        Our fleet includes all latest models with neat & clean cars like BMW, Mercedes,Honda, Toyota etc. Get with Professional Drivers in Karachi or all over Pakistan.


        </p> */}
             {/* <p class="title mb-2 text-center" style={{color : "black", fontSize : "15px",fontWeight : "500"}}><b>
( Note : With Driver Only Car Fuel and Tools Included )

</b> 

</p> */}
          <div class="row justify-content-center">
              <div class="col-lg-4 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#FE7448" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Toyota Corolla


</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup1} onChange={(e)=>setpickup11(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop1} onChange={(e)=>setdrop11(e.target.value)} >
                   <option value="">Choose an option</option>
                     <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car1">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a class="btn btn-round" style={{background : "white",color  : "black"}} onClick={()=>setCarPick1()}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#FE7448" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo2} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Land Cruiser V8



</h3>
<br />
<div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup2} onChange={(e)=>setpickup21(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop2} onChange={(e)=>setdrop21(e.target.value)} >
                   <option value="">Choose an option</option>
                         <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car2">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick2()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-4 col-sm-6">
                  <div class="icon-box text-center cfg" style={{padding  :"0px 13px" ,height : "500px",borderRadius : "20px",color : "white", background : "#FE7448" }} >
                          {/* <i class="icon-local_car_wash"></i> */}
                          <span class="icon-box-icon cfg" style={{padding  :"0px" , }}>
 
                          <img src={Toyo3} alt="" style={{height: "168px",width: "262px"}}/>
                          </span>

                      <div class="icon-box-content" style={{}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> Prado TX



</h3>
<br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">PICK-UP CITY</span>
                    
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={pickup3} onChange={(e)=>setpickup31(e.target.value)} >
                    <option value="">Choose an option</option>
                    <option value="islamabad">Islamabad</option>
                    <option value="karachi">Karachi</option>
                    <option value="lahore">Lahore</option>
                    <option value="multan">Multan</option>
                    </select>
                     
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                  <br />
                  <div class="form-group" style={{width: "190px", margin : "auto" }}>
										<span class="form-label">Dropoff Location</span>
                    <select class="form-control"  required="required" aria-required="true" style={{height : '40px'}}  value={drop3} onChange={(e)=>setdrop31(e.target.value)} >
                   <option value="">Choose an option</option>
                         <option value="Thano Bula Khan">Thano Bula Khan</option>
    <option value="Hyderabad">Hyderabad</option>
    <option value="Jamshoro">Jamshoro</option>
    <option value="Hala">Hala</option>
    <option value="Sakrand">Sakrand</option>
    <option value="Sehwan">Sehwan</option>
    <option value="Moro">Moro</option>
    <option value="Lahore">Lahore</option>
    <option value="Multan">Multan</option>
    <option value="Karachi">Karachi</option>
    <option value="Gwadar">Gwadar</option>
    <option value="Islamabad">Islamabad</option>
    <option value="Peshawar">Peshawar</option>
    <option value="Sargodha">Sargodha</option>
    <option value="Sialkot">Sialkot</option>
    <option value="Nawabshah">Nawabshah</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Larkana">Larkana</option>
    <option value="Muzaffarabad">Muzaffarabad</option>
    <option value="Quetta">Quetta</option>
    <option value="Dadu">Dadu</option>
    <option value="Kandiaro">Kandiaro</option>
    <option value="Setharja">Setharja</option>
    <option value="Gambat">Gambat</option>
    <option value="Khairpur">Khairpur</option>
    <option value="Sukkur">Sukkur</option>
    <option value="Pano Aqil">Pano Aqil</option>
    <option value="Ghotki">Ghotki</option>
    <option value="Mirpur Mathelo">Mirpur Mathelo</option>
    <option value="Sadiqabad">Sadiqabad</option>
    <option value="Rahim Yar Khan">Rahim Yar Khan</option>
    <option value="Zahir Pir">Zahir Pir</option>
    <option value="Khanpur">Khanpur</option>
    <option value="Uch">Uch</option>
    <option value="Ahmadpur East">Ahmadpur East</option>
    <option value="Bahawalpur">Bahawalpur</option>
    <option value="Kahror Pakka">Kahror Pakka</option>
    <option value="Dunyapur">Dunyapur</option>
    <option value="Khanewal">Khanewal</option>
    <option value="Mian Channun">Mian Channun</option>
    <option value="Kamalia">Kamalia</option>
    <option value="Gojra">Gojra</option>
    <option value="Faisalabad">Faisalabad</option>
    <option value="Chak Jhumra">Chak Jhumra</option>
    <option value="Pindi Bhattian">Pindi Bhattian</option>
    <option value="Rabwah">Rabwah</option>
    <option value="Bhalwal">Bhalwal</option>
    <option value="Khewra">Khewra</option>
    <option value="Chakwal">Chakwal</option>
       <option value="Abbottabad">Abbottabad</option> 
      <option value="Gilgit">Gilgit</option>
    <option value="Attack">Attack</option>
    <option value="Kohat">Kohat</option>

    <option value="Mansehra">Mansehra</option>
    <option value="Haripur">Haripur</option>
    <option value="Badgram">Badgram</option>
 
                   </select>
                    
                    <span class="select-arrow"></span>

										{/* <input type="text" placeholder="City or airport"/> */}
									</div>
                          <br />
                          <p className="cgg1" id="car3">Select City
</p>
<div class="col-12 col-lg-12 col-xl-12" style={{margin : "auto"}}>
		                	<div class="btn-wrap">
		                		{/* <span>Round Style</span> */}
		                		<a  onClick={()=>setCarPick3()} class="btn btn-round" style={{background : "white",color  : "black"}}><i class="icon-long-arrow-right"></i><span>Book Now</span></a>
		                	</div>
		                </div>
                      </div>
                  </div>
              </div>
              </div>
             
</div>

<br />






















           <div className="container banner-group-1">
               
               <div className='container' style={{background:"rgb(240, 240, 240)"}}>
                  {/* <div className='container' style={{backgroundImage: 'url('+ab+')',backgroundPosition: "center",
     backgroundRepeat: "no-repeat",
     backgroundSize: "cover",}}> */}
                   <br/>
              <p class="text-center">AFFORDABLE CARS IN Karachi
 
 
 </p>
              <h2 class="title mb-4 text-center">Why <b>rentacarinkarachi.com?</b> 
 
 </h2>
                     <div class="row justify-content-center">
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                 <span class="icon-box-icon" style={{padding  :"0px" , }}>
                                     {/* <i class="icon-local_car_wash"></i> */}
                                     <img src={ch344} alt="" style={{height   : "40px"}}/>
                                 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> First Class Service
 
 </h3>
                                     <p className="cgg">Experience first-class service that surpasses expectations.
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch3444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>24/7 Customer <br /> Support
 </h3>
                                     <p className="cgg" style={{}}>Call us Anywhere Anytime
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch34444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Clean and safe car
 </h3>
                                     <p className="cgg">Efficient safety and cleanliness checks
 
 </p>
                                 </div>
                             </div>
                         </div>
 
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch3444444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Reservation Anytime  
 </h3>
                                     <p className="cgg">24/7 Online Reservation
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <WhatshotIcon alt="" style={{height   : "40px",color : "black",fontSize:"60px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Quality at Minimum Expense
  
 </h3>
                                     <p className="cgg">Delivering quality at a Minimum Spend.
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                         <div class="col-lg-4 col-sm-6">
                             <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                                                             <span class="icon-box-icon" style={{padding  :"0px" , }}>
 
                             <img src={ch34444444} alt="" style={{height   : "40px"}}/>
 </span>
                                 <div class="icon-box-content">
                                     <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Free Pick-Up & Drop-Off
 
  
 </h3>
                                     <p className="cgg">Enjoy complimentary pick-up and drop-off services.
 
 
 
 
 </p>
                                 </div>
                             </div>
                         </div>
                     </div>
 </div>
 
 
 
 
 






  <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Best Car Rental at Rent a Car in Karachi
</h3>

                <div>
            

<Carousel1/>

                       
                </div>  
              </div>

           
             



  {/* <div className="container deal-section">
                <h3 className="title text-center font-weight-bold mt-3"> Best Car Rental at Falcon Rides Rent a Car
</h3>

                <div>
            

<Carousel2/>

                       
                </div>  
              </div> */}

           
             


















          


             



                {/* <div className="container">
                <div className="row">
                  <div className="col-12" style={{padding : "0px"}}>
                    <div className="">
                      <div className="bg-image d-flex justify-content-center kjkjkjkj" style={{backgroundImage: 'url('+ch343+')',backgroundRepeat : "no-repeat" ,height:  "410px",backgroundColor : "transparent"}}>
                       
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

<br/>

              <div className="container deal-section">
                <div>
            

<IconBox/>

                       
                </div>  
              </div>
             



<div className="container banner-group-1">
               
               <div className='container' style={{background:"rgb(240, 240, 240)"}}>
               {/* <div className='container' style={{backgroundImage: 'url('+ab16+')',backgroundRepeat : "no-repeat" , backgroundSize : "cover"}}> */}
                 <br/>
            <p class="text-center" >Services


</p>
          
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Car Rental Booking
</h3>
                                   <p className="cgg">Experience luxury and convenience with our chauffeur-driven car rental service. Whether you're traveling for business or pleasure, sit back and relax as our professional chauffeurs take the wheel.





</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}>Tours Booking
</h3>
                                   <p className="cgg" style={{}}>


Book your next adventure with Rent a Car in Karachi and receive the added benefit of a travel plan. Explore with peace of mind, knowing that you're protected against unforeseen circumstances.
</p>
                               </div>
                           </div>
                       </div>

                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "200px",color : "black", background : "white"}} >
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",fontSize : "22px"}}> Pick-Up & Drop-Off
</h3>
                                   <p className="cgg">Effortless pick-up and drop-off services tailored for your convenience. Experience seamless transportation with us. Your journey, our priority.



</p>
                               </div>
                           </div>
                       </div>
                       
                   </div>
</div>
</div>



{/* <div className='container' style={{background :  "#FE7448"}}>
          
          <div class="row justify-content-center">
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#FE7448" , display : "flex"}} >

                  <img src={ch34444} alt="" style={{height   : "40px"}}/>

                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}>50+ 
</h3>
                          <p className="cgg1" style={{}}>NO. OF CARS

</p>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#FE7448" , display : "flex"}} >
                          <img src={ch344} alt="" style={{height   : "40px"}}/>
                    
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 5,000+

</h3>
                          <p className="cgg1">HAPPY CUSTOMERS




</p>
                      </div>
                  </div>
              </div>


              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#FE7448" , display : "flex"}} >

                  <img src={ch34444444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 6
</h3>
                          <p className="cgg1">Operate In Locations

</p>
                      </div>
                  </div>
              </div>
              
              <div class="col-lg-3 col-sm-6">
                  <div class="icon-box text-center" style={{padding  :"30px 13px" ,height : "100px",justifyContent  : "center",color : "white", background : "#FE7448" , display : "flex"}} >

                  <img src={ch3444} alt="" style={{height   : "40px"}}/>
                      <div class="icon-box-content" style={{paddingLeft:  "20px"}}>
                          <h3 class="icon-box-title cgg1" style={{fontWeight : "600",fontSize : "22px" , margin : "0px",padding  :  "0px"}}> 24/7
</h3>
                          <p className="cgg1">Quality Support

</p>
                      </div>
                  </div>
              </div>
              
          </div>
</div>

<br />


<ChooseUs />


<Testimonials /> */}



{/* 
<section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h2>Service Areas</h2>
            </div>

            <div className="plan-container__boxes">
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-al-karama">
                <center>
                <h6>Car Rental Service in Al Karama Dubai</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-al-quoz">
                <center>

                <h6>Car Rental Service in Al Quoz</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-al-Satwa">
                <center>

                <h6>Car Rental Service in Al Satwa</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-burj-al-arab">
                <center>
                <h6>Car Rental Service in Burj Al Arab</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-burj-khalifa">
                <center>

                <h6>Car Rental Service in Burj Khalifa</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-frame">
                <center>

                <h6>Car Rental Service in Dubai Frame</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-marina">
                <center>
                <h6>Car Rental Service in Dubai Marina</h6>
               
                </center>
              </Link>

              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-emirates-hills">
                <center>

                <h6>Car Rental Service in Emirates Hills</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-jumeirah-beach">
                <center>

                <h6>Car Rental Service in Jumeirah Beach</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-palm-jumeirah">
                <center>

                <h6>Car Rental Service in Palm Jumeirah</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-downtown">
                <center>

                <h6>Car Rental Service in Dubai Downtown</h6>
                
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Deira">
                <center>

                <h6>Car Rental Service in Deira</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-hills">
                <center>

                <h6>Car Rental Service in Dubai Hills</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-sheikh-zayed-road">
                <center>

                <h6>Car Rental Service in Sheikh Zayed Road</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-dubai-silicon-oasis">
                <center>

                <h6>Car Rental Service in Dubai Silicon Oasis</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Abu-Dhabi">
                <center>

                <h6>Car Rental Service in Abu Dhabi</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Ajman">
                <center>

                <h6>Car Rental Service in Ajman</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Dubai-Fountain">
                <center>

                <h6>Car Rental Service in Dubai Fountain</h6>
               
                </center>
              </Link>
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Ski-Dubai">
                <center>

                <h6>Car Rental Service in Ski Dubai</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Dubai-Miracle-Garden">
                <center>

                <h6>Car Rental Service in Dubai Miracle Garden</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Sharjah">
                <center>

                <h6>Car Rental Service in Sharjah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Fujairah">
                <center>

                <h6>Car Rental Service in Fujairah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Al-Ain">
                <center>

                <h6>Car Rental Service in Al Ain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Desert-Safari">
                <center>

                <h6>Car Rental Service in Desert Safari</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Ras-Al-Khaimah">
                <center>

                <h6>Car Rental Service in Ras Al Khaimah</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-Umm-Al-Quwain">
                <center>

                <h6>Car Rental Service in Umm Al Quwain</h6>
               
                </center>
              </Link>
              
              <Link className="plan-container__boxes__box" style={{cursor:"pointer"}} to="/car-rental-service-in-One-&-Only-One-Za'abeel">
                <center>

                <h6>Car Rental Service in One & Only One Za'abeel</h6>
               
                </center>
              </Link>
              
             
             
            </div>
          </div>
        </div>
      </section> */}



<div className="container product-group mb-lg-7 mb-4">
                <div className="row justify-content-center">

            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rentacarkarachi">Best Rent A Car in Karachi</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Karachi-Pakistan">Rent A Car Karachi Pakistan</Link></p>

                                               <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-karachi-with-driver">Rent A Car karachi with driver</Link></p>
                                               <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-karachi">Rent A Car karachi</Link></p>
                                            
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Gulistan-e-Johar">Rent A Car Gulistan-e-Johar</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/Bullet-Proof-Vehicles-At-Car-Rentals-karachi-Pakistan">Bullet Proof Vehicles At Car Rentals karachi Pakistan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-clifton"> Rent A Car Clifton</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-dolmen-city"> Rent A Car Dolmen City</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-empress-market"> Rent A Car Empress Market</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-gulberg"> Rent A Car Gulberg</Link></p>
                                            

                                             

                          
              
            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                           
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Pakistan">Rent A Car Pakistan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Karachi-Pakistan">Rent A Car Peshawar</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Islamabad">Rent A Car Islamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-airport-pick-up &-drop-off">Rent A Car Airport Pickup & Drop Off</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-gulzar-e-hijri"> Rent A Car Gulzar-e-Hijri</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-liaquatabad"> Rent A Car Liaquatabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-lucky-one-mall"> Rent A Car Lucky One Mall</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-m-a-jinnah-road"> Rent A Car M.A. Jinnah Road</Link></p>
                                            

            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                            
                                    
                                             
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Lahore">Rent A Car Lahore</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Multan">Rent A Car Multan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Hyderabad">Rent A Car Hyderabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Wedding-Rent-A-Car">Rent A Car Wedding Transport</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-new-karachi"> Rent A Car New Karachi</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-nazimabad"> Rent A Car Nazimabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-north-nazimabad"> Rent A Car North Nazimabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-port-grand"> Rent A Car Port Grand</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-quaid-e-azam-museum"> Rent A Car Quaid e Azam Museum</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-turtle-beach"> Rent A Car Turtle Beach</Link></p>
            </div>
            </div>
            </div>






            <hr className="mt-2 mb-0" />
              </div>
              <br />



           
              
{/*           
<div class="container">
	                	<h2 class="title text-center mb-3">Our Offices</h2>

	                	<div class="row">
	                		<div class="col-lg-12">
	                			<div class="store">
	                				<div class="row">
	                					<div class="col-sm-5 col-xl-6">
	                						<figure class="store-media mb-2 mb-lg-0">
	                							<img src={logo} alt="image"/>
	                						</figure>
	                					</div>
	                					<div class="col-sm-7 col-xl-6">
	                						<div class="store-content">
	                							<h3 class="store-title">Falcon Rides Rent a Car, Tours & transport, 
</h3>
	                							<address>     38 4 B St - Al Karama - Dubai - UAE
Dubai</address>
	                							<div>                           <a href="tel:+971563619373">+971 56 361 9373</a></div>
	                						
	                						

	                							<h4 class="store-subtitle">Office Open Hours:</h4>
                								<div>Monday - Sunday 24/7 Hours</div>

                								<a href="https://www.google.com/maps/dir//Chand+Tours+%26+Car+Rental+Agency+Lahore+DHA/@31.4751552,74.3199148,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x39190759509a2d3d:0xbe427f23fada2ed5!2m2!1d74.4023161!2d31.4751816?entry=ttu" class="btn btn-link" target="_blank"><span>View Map</span><i class="icon-long-arrow-right"></i></a>
	                						</div>
	                					</div>
	                				</div>
	                			</div>
	                		</div>
	                		</div>

             
</div>
              */}

        
      {/* <Faq/>      */}
<br />
<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
 
            <h2>Welcome to Rent a Car in Karachi</h2>
<p>Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models like KIA, Hyundai, and MG. At Rent a Car in Karachi, we're committed to delivering an unparalleled experience of comfort, convenience, and excellence.</p>
<h2>🌟 Our Mission</h2>
<p>Redefining the rental car experience through quality service and customer satisfaction. Whether it's a business trip or a family vacation, we ensure every journey is a memorable adventure.</p>
<h2>🚗 Fleet Excellence</h2>
<p>Experience peak performance with our meticulously maintained fleet, featuring the efficiency of the Toyota Corolla, KIA, and Hyundai. Choose Rent a Car in Karachi for quality and precision.</p>
<h2>🚗 Rental Solutions</h2>
<p>From short-term daily rentals to long-term leases, we provide customizable packages to suit your unique needs. With our commitment to versatility, you'll have the perfect vehicle for every occasion.</p>
<h2>📅 Book Now</h2>
<p>Elevate your travel experience with Rent a Car in Karachi! Book with us today and experience top-tier transportation solutions.</p>
            </div>
            </div>
            </div>



              
              {/* End .blog-posts */}
            </main>
            
            {/* End .footer */}
          </div>
          <button id="scroll-top" title="Back to Top">
            <i className="icon-arrow-up" />
          </button>
       
        </div>
        )
    
}

export default Home;