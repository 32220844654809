import { Link } from "react-router-dom";
import Logo from "../images/logo/logo.png";
import { useState } from "react";
// import { IconMenu2, IconX } from "@tabler/icons-react";
import logo1 from '../assets/images/logo-3.png';


// import StreamIcon from '@material-ui/icons/Stream';
import MenuIcon from '@material-ui/icons/Menu';
import Brightness7Icon from '@material-ui/icons/Brightness7';




function Navbar() {
  const [nav, setNav] = useState(false);

  const openNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <Brightness7Icon width={30} height={30}  style={{fontSize: "34px"}}/>
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/categories/Cars/all-products">
              Car Collection
              </Link>
            </li>
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/cart">
                View Cart
              </Link>
            </li>
            {/* <li>
              <Link className="fgfgfg" onClick={openNav} to="/team">
                Our Team
              </Link>
            </li> */}
            <li>
              <Link className="fgfgfg" onClick={openNav} to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* desktop */}

        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={logo1} className="der1" alt="logo-img" style={{width: "290px",
    height: "125px",
    maxWidth: "240px",objectFit: "contain"}}/>
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
              <Link className="home-link fgfgfg"  to="/">
                Home
              </Link>
            </li>
            <li>
              {" "}
              <Link className="about-link fgfgfg" to="/about">
                About
              </Link>
            </li>
            <li>
              {" "}
              <Link className="models-link fgfgfg" to="/categories/Cars/all-products">
              Car Collection
              </Link>
            </li>
            <li>
              <Link className="models-link fgfgfg" onClick={openNav} to="/cart">
                View Cart
              </Link>
            </li>
            {/* <li>
              {" "}
              <Link className="testi-link fgfgfg" to="/testimonials">
                Testimonials
              </Link>
            </li> */}
            {/* <li>
              {" "}
              <Link className="team-link fgfgfg" fgfgfg to="/team">
                Our Team
              </Link>
            </li> */}
            <li>
              {" "}
              <Link className="contact-link fgfgfg" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <div className="navbar__buttons">
            {/* <Link className="navbar__buttons__sign-in" to="/">
              Sign In
            </Link> */}
            {/* <Link> */}
              <a className="navbar__buttons__register" href="tel:+971563619373">

              Call Now
              </a>
            {/* </Link> */}
          <Link to="/cart">
            <div className="icon">
              <i className="icon-shopping-cart" style={{fontSize:"34px"}}/>
              {/* <span className="cart-count">{ cart1 ? cart1.length :0}</span> */}
            {/* <p>Cart</p> */}
            </div>
          </Link>
          </div>
          {/* mobile */}
          <div className="mobile-hamb">
            <Link to="/cart">
            <div className="icon">
              <i className="icon-shopping-cart" style={{fontSize:"34px"}}/>
              {/* <span className="cart-count">{ cart1 ? cart1.length :0}</span> */}
            {/* <p>Cart</p> */}
            </div>
          </Link>
            <MenuIcon onClick={openNav} width={30} height={30} style={{fontSize:"29px",marginTop: "17px",marginLeft:"10px",color :"#96040e"}}/>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
