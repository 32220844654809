import React, { useEffect , useState } from 'react';
import swal from 'sweetalert';
import Loader from "react-loader-spinner";



import { Link , Redirect} from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GradeIcon from '@material-ui/icons/Grade';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import logo1 from './images/x1.jpg';

// import swal from 'sweetalert';
import Swal from 'sweetalert2'

import Carousel2 from './Carosel1';



export function SubCategoriesProductDetails(props) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  const [productDetail , setproductDetail] = useState([])
  const [Price , setPrice] = useState(1)
  const [DataPart2 , setDataPart2] = useState([])
  const [allproduct , setallproduct] = useState([])
  const [allproductReview , setallproductReview] = useState([])
  const [Average , setAverage] = useState(0)

  const [product , setproduct] = useState([])

  const [Area1 , setArea1] = useState(true)
  const [Area2 , setArea2] = useState(false)
  const [Area3 , setArea3] = useState(false)
  const [MainCatogories , setMainCatogories] = useState([])

  const [dd1 , setdd1] = useState(true)
  const [dd2 , setdd2] = useState(false)
  const [ImageCha , setImageCha] = useState([])


  const [Star1 , setStar1] = useState(0)
  const [Star2 , setStar2] = useState(0)
  const [Star3 , setStar3] = useState(0)
  const [Detail, setDetail] = useState("") 



  const [Changer , setChanger] = useState(0)

  const [status , setstatus] = useState(1)


  const [name, setname] = useState("") 
  const [lname, setlname] = useState("") 
  const [email, setemail] = useState("") 
  const [subject, setsubject] = useState("") 
  const [Message, setMessage] = useState("") 

  const [CategoriesName , setCategoriesName] = useState({})

  const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    const [fg , setfg] = useState(true)
    const [couponShow , setcouponShow] = useState(false)
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [IterationForSlider , setIterationForSlider] = useState(9)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])



    useEffect(() =>{
        window.scrollTo(0, 0)

        let Categoriesname = props.match.params ;
        setCategoriesName(Categoriesname)
        fetch("https://falcon-rides-main-back.vercel.app/AllProduct1/"+Categoriesname.productId,{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res=>res.json())
    .then(res1=>{
        console.log(res1)
        setproductDetail(res1)
        setDetail(res1[0].Product_Long_Notes)
        setImageCha([res1[0].Product_Image_Upload])
          if(res1[0].Product_Image_Upload1){
            setImageCha([res1[0].Product_Image_Upload,res1[0].Product_Image_Upload1])
          }
          if(res1[0].Product_Image_Upload2){
            setImageCha([res1[0].Product_Image_Upload,res1[0].Product_Image_Upload1,res1[0].Product_Image_Upload2])
          }
          if(res1[0].Product_Image_Upload3){
            setImageCha([res1[0].Product_Image_Upload,res1[0].Product_Image_Upload1,res1[0].Product_Image_Upload2,res1[0].Product_Image_Upload3])
          }
          if(res1[0].Product_Image_Upload4){
            setImageCha([res1[0].Product_Image_Upload,res1[0].Product_Image_Upload1,res1[0].Product_Image_Upload2,res1[0].Product_Image_Upload3,res1[0].Product_Image_Upload4])
          }
          if(res1[0].Product_Image_Upload5){
            setImageCha([res1[0].Product_Image_Upload,res1[0].Product_Image_Upload1,res1[0].Product_Image_Upload2,res1[0].Product_Image_Upload3,res1[0].Product_Image_Upload4,res1[0].Product_Image_Upload5])
          }
        fetch("https://falcon-rides-main-back.vercel.app/allReview",{
        method: "GET",
         headers :  {
         "Content-Type" : "application/json" , 
     }
    })
    .then(res3=>res3.json())
    .then(res4=>{
      let a = []
      res4.map((res13)=>{
      res1[0].Review.map((res14)=>{
        console.log(res14 , res13.productId)
          if(res14 === res13.productId){
            a.push(res13)

          }
      

      })
      })

      setallproductReview(a)

        console.log(res4,a) 
    
    })
    })
        fetch("https://falcon-rides-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
          fetch("https://falcon-rides-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories)
            if(res3[j].MainCategories === res13[i].MainCategories){
              // a.push("1")
// console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate1(a)
        })
      })


      fetch("https://falcon-rides-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)


            fetch("https://falcon-rides-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res12=>res12.json())
        .then(res13=>{
          let a = []
          // console.log(res3,res13)
          for (let i = 0; i < res13.length; i++) {
          for (let j = 0; j < res3.length; j++) {
            // console.log(res3[j].MainCategories , res13[i].MainCategories , res3[j].CategoriesName , res13[i].CategoriesName )
            if(res3[j].MainCategories === res13[i].MainCategories && res3[j].CategoriesName === res13[i].CategoriesName ){
              // a.push("1")
console.log(i,j)
              a[i] = "1"
              break
            }
            else{
              a[i] = "0"
              // a.pusH(("0"))
            }
            
          }
          }
          // console.log(a)
          setCate2(a)
        })
        })
        fetch("https://falcon-rides-main-back.vercel.app/AllProduct",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       }
      })
      .then(res5=>res5.json())
      .then(res6=>{
       //    console.log(res6);
       const pro = []
       res6.map((res7,i)=>{
           //    console.log(res7.Product_Popular );
           if ( res7.Arrival3 ){
               //    console.log(res7);
               //         // return setproduct1(...product1 , res3)
               pro.push(res7)
           }
       })
       // setproduct(pro.reverse())
       setproduct(pro)
      //  setdis(true)
      })

        
    fetch("https://falcon-rides-main-back.vercel.app/AllProduct",{
      method: "GET",
       headers :  {
       "Content-Type" : "application/json" , 
   }
  })
  .then(res5=>res5.json())
  .then(res6=>{
   //    console.log(res6);
   const pro = []
   const pro1 = []
   res6.map((res7,i)=>{
       //    console.log(res7.Product_Popular );
       if ( res7.Arrival3 ){
        //    console.log(res7);
        //         // return setproduct1(...product1 , res3)
        pro.push(res7)
    }
   })
   // setproduct(pro.reverse())
  //  setproduct8(pro)
  //  setproduct9(pro1)
   setproduct(pro)
  //  setproduct1(pro1.slice(0,8))
   // setproduct1(pro1.slice(0,7))
   // setproduct1(pro1)
  //  setdis(true)
  })
   
    fetch("https://falcon-rides-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            // console.log(data);
            setSubClassCategories(data)
        })
        fetch("https://falcon-rides-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            // console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            // console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        fetch("https://falcon-rides-main-back.vercel.app/AllCategories",{
          method: "GET",
           headers :  {
           "Content-Type" : "application/json" , 
       } ,
      })
      .then(res2=>res2.json())
      .then(res3=>{
          // console.log(res3);
          // const Data1 = res3.filter((res4,i)=>{
          //     if (res4.MainCategories === props.history.location.pathname.split("/")[2])
          //     return res4
          // })
          // console.log(Data1,res3)
          setSubCategories(res3)
          // setSubCategoriesname(res3)
      
      })
    
      var data1 =  JSON.parse(localStorage.getItem("Cart")) 
       if (data1) setDataPart2(data1)

  
    },[])


const setChanger5=(e)=>{
        document.getElementById("change-photo-zoom").src = e
    }




const minus=()=>{
        if(Price > 1)
        setPrice(Price - 1)
        console.log(Price);
    }
    const plus=()=>{
        if(Price < 100)
        setPrice(Price + 1)
        console.log(Price);

    }
  
    const sdfjkfkjfd = () =>{
      // swal("Update!", "Your Service Add in Cart!", "success");
      console.log("double");

      // if(productDetail){
          // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          Swal.fire({
            title: 'Your Rent a Car in Cart!',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Coutinue Rent a Car',
            denyButtonText: `View Cart`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              // Swal.fire('Saved!', '', 'success')
            } else if (result.isDenied) {
              props.history.push("/cart")
              // Swal.fire('Changes are not saved', '', 'info')
            }
          })
    
    
    
        
          if (productDetail[0].Product_Price_Discounted === null){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : Price ,
                Total_Product_Price  : productDetail[0].Product_Price * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : Price + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price * Price )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

            }
            else if (productDetail[0].Product_Price_Discounted){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : Price ,
                Total_Product_Price  : productDetail[0].Product_Price_Discounted * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : Price + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price_Discounted * Price )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

            }
            else if (productDetail[0].Size_Discounted[0]){
                
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        Size : productDetail[0].Size[Changer],
                        Product_Price_Discounted : productDetail[0].Size_Discounted[Changer],
                        Total_Product_Price  : productDetail[0].Size_Discounted[Changer] * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[Changer]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : Price + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Size_Discounted[Changer] * Price )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }

            }
            else{




                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : Price ,
                        Size : productDetail[0].Size[Changer],
                        Product_Price_Discounted : productDetail[0].Price[Changer],
                        Total_Product_Price  : productDetail[0].Price[Changer] * Price }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[Changer]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : Price + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Price[Changer] * Price )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }

            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 

            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )

            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]

            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )

            }


            }
          
                
          
                          
    
    }
  
    const addtocartproduct123 = () =>{
      swal("Update!", "Your Service Add in Cart!", "success");
    
      // if(productDetail){
          // console.log(typeof(productDetail[0].ProductCreater), typeof("60a3c644e4520a12c80a6f52"));
          
    
    
        
          if (productDetail[0].Product_Price_Discounted === null){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Total_Product_Price  : productDetail[0].Product_Price * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : 1 + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
            }
            else if (productDetail[0].Product_Price_Discounted){
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id ){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
            return {...item,
                Pieces : 1 + item.Pieces,
                Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
            }
            else{
            console.log("double not match");
            return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
            }
            else if (productDetail[0].Size_Discounted[0]){
                
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : 1 ,
                        Size : productDetail[0].Size[0],
                        Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                        Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : 1 + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
            }
            else{
          
          
          
          
                    const data =  {...productDetail[0] ,
                        Percentage : 0,
                        DeliveryStatus : "Pending",
                        Pieces : 1 ,
                        Size : productDetail[0].Size[0],
                        Product_Price_Discounted : productDetail[0].Price[0],
                        Total_Product_Price  : productDetail[0].Price[0] * 1 }
            var data1 = JSON.parse(localStorage.getItem("Cart")) 
            if (data1){
            var data3 = data1.map((item) => {
                if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
                    console.log("double");
            ;                   localStorage.setItem("double",JSON.stringify(true))
                return {...item,
                    Pieces : 1 + item.Pieces,
                    Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
            }
            else{
                console.log("double not match");
                return item
            }
          
            })
            var data5 =  JSON.parse(localStorage.getItem("double")) 
            console.log(DataPart2.length, data3.length,data5);
            var data10 =  JSON.parse(localStorage.getItem("Cart")) 
          
            if(data10.length=== data3.length && data5){
            console.log("double remove");
            localStorage.removeItem("double")
            localStorage.setItem("Cart" , JSON.stringify(data3) )
          
            }
            else{
            console.log("Differet");
            var data2 = [...data1 , data]
          
            localStorage.setItem("Cart" , JSON.stringify(data2) )
            }
            }
            else{
            console.log("1");
            localStorage.setItem("Cart" , JSON.stringify([data]) )
          
            }
          
          
            }
          
          
                
    
                          
  props.history.push("/cart")
    }
const savethedetailproduct = (data) =>{
    localStorage.setItem("Data" , JSON.stringify(data) )
    console.log(data);
 }

const  setImageChange = (e)=>{
    document.getElementById("carrouselimg1").src = e
}

const change_detail_area = (e)=>{
    if (e === 1){
        setArea1(true)
        setArea2(false)
        setArea3(false)
    }
    else if (e === 2){
        setArea1(false)
        setArea2(true)
        setArea3(false)
    }
    else if (e === 3){
        setArea1(false)
        setArea2(false)
        setArea3(true)
    }
}

const RatingProduct1 = (e)=>{
    setStar1(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").firstChild.children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").firstChild.children[i].style.color  = "yellow"
    }
}
const RatingProduct2 = (e)=>{
    setStar2(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").children[1].children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    // document.getElementById("Rate").children[1].children[e-1].style.color  = "yellow"
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").children[1].children[i].style.color  = "yellow"
    }
}
const RatingProduct3 = (e)=>{
    setStar3(e)
    let a = e-1
    for (let i = 0; i < 5; i++) {
        document.getElementById("Rate").children[2].children[i].style.color  = "rgba(192, 186, 186, 0.432)"
    }
    for (let i = 0; i < a+1; i++) {
        document.getElementById("Rate").children[2].children[i].style.color  = "yellow"
    }
}
    // document.getElementById("Rate").children[2].children[e-1].style.color  = "yellow"

// }
const changePrice = (e)=>{
    setChanger(e)
    // console.log("sdcefelwjfklj");
    // if(document.getElementById("PriceChanger")){
        if(productDetail[0].Size_Discounted[0]){
              document.getElementById("PriceChanger2").innerHTML ="Rs : "+ productDetail[0].Price[e]
        // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
        document.getElementById("PriceChanger3").innerHTML =(100 -(productDetail[0].Size_Discounted[e] *100) /  productDetail[0].Price[e]) +" % off"
        document.getElementById("PriceChanger4").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
    }
    else{
        document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
    }
      
        // "Rs : "+ productDetail[0].Size_Discounted[e]
        // let Price = productDetail[0].Price[e]
        // de.innerHTML = Price
    // }

}

const setReviewChange = ()=>{
    // setChanger(e)
    setArea1(false)
    setArea2(false)
    setArea3(true)
        // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Price[e]
  

}


const submitUserReview = (e)=>{
    e.preventDefault()
    fetch("https://falcon-rides-main-back.vercel.app/createReview",{
        method: "POST",
        headers :  {
            "Content-Type" : "application/json" , 
        } ,
        body : JSON.stringify({
            user:name,
            review_message:Message ,
            rate:Star1 ,
            // value:Star2 ,
            // price:Star3 ,
            productId:CategoriesName.productid
        }),
    })
    .then(res=>res.json())
    .then((res2)=>{        
    if(res2.Error){
        swal(res2.Error);
     }
     else{
        swal("Successfully Submit your Review!");
        setname("")
        setMessage("")
        setStar1(0)
     }
    })
       
}
// const changePrice1 = (e)=>{
//     setChanger(e)
//     // console.log("sdcefelwjfklj");
//     // if(document.getElementById("PriceChanger")){
//         document.getElementById("PriceChanger2").innerHTML ="Rs : "+ productDetail[0].Price[e]
//         // document.getElementById("PriceChanger").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//         document.getElementById("PriceChanger3").innerHTML =(100 -(productDetail[0].Size_Discounted[e] *100) /  productDetail[0].Price[e]) +" % off"
//         document.getElementById("PriceChanger4").innerHTML ="Rs : "+ productDetail[0].Size_Discounted[e]
//         // "Rs : "+ productDetail[0].Size_Discounted[e]
//         // let Price = productDetail[0].Price[e]
//         // de.innerHTML = Price
//     // }

// }
const setShoesSize = (e)=>{
    document.getElementById("myDiv1234").style.display= "block"
    let  m = document.getElementById("myDiv23456")
    m.src = e
}
const setMainPhoto = (e)=>{
    console.log(e);
    document.getElementById("mainphoto").style.display= "block"
    let  m = document.getElementById("mainphoto2")
    m.src = e
}

const setChanger1 = (e)=>{
    document.getElementById("myDiv1234").style.display= "none"
}
const setChanger2 = (e)=>{
    document.getElementById("mainphoto").style.display= "none"
}

const setChatNow = ()=>{
    document.getElementById("myDivUmer").style.display= "block"
    // console.log("rtgd")
}
const setChanger22 = (e)=>{
    document.getElementById("myDivUmer").style.display= "none"
  }


  



  const SubmitDataMessage = (e) =>{
    e.preventDefault()
    //   if(message.length >= 1 ){
    //     const msgDetails = {
    //     message : message , 
    //     user_uid_1 : Auth.uid ,
    //     user_uid_2 : specificUserChat.uid ,

    // }
    // // dispatch(sendMessageFromDatabase(msgDetails))
    // setMessage("")
    // if(!Auth.uid ){
    //     console.log("-")
    //     // dispatch(UserKeyUpNoTypingShow(User.uid))
    //     }
    // else{
    //         console.log("-")
    //         // dispatch(UserKeyUpNoTypingShow(Auth.uid))
    //     }
    
    // }
}

const setMess1 = () =>{
    localStorage.setItem("Details Product" , JSON.stringify(productDetail))
    props.history.push("/chat")
}


const setChangerItemSize = (e)=>{

  if (fg) setfg(false)
  else setfg(true)

  for (let i = 0; i < 45; i++) {
    if(i === e){
      if(fvalue[e] === 0){
          fvalue[e] = 1
          // let f = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(180deg)"
          
      }
      else {
          fvalue[e] = 0
          // let v = document.getElementById("changeIcon"+i)
           document.getElementById("changeIcon1"+i).style.transform = "rotate(0deg)"
          // v.innerHTML= ""
       }
    }
 
  }

setfvalue(fvalue)
  if (fg) setfg(false)
  else setfg(true)
  
}
    const carrouselInit =() => {

      var carrousel = document.getElementById("carrouselimg1");
      carrousel.style.opacity = "1";
      // setInterval(carrouselAutoChange, 5000);
    
    }
    
    const carrouselAutoChange=()  =>{
      carrouselRight();
    }
    
    
    
    const carrouselChange=(i) => {
    
      var carrousel = document.getElementById("carrouselimg" + i);
      carrousel.style.opacity = "1";
    
    }
    
    const setChan = (i) =>{
      console.log(i)
       if(      document.getElementById("categ"+i).style.display  ==="block")
      document.getElementById("categ"+i).style.display  ="none"
      else
      document.getElementById("categ"+i).style.display  ="block"
    }
    const setChan1 = (i,data) =>{
      //  let  e =  []
      //   for (let s = 0; s < SubCategories.length; s++) {
      //     if(data === SubCategories[s].MainCategories)
      //     e.push( SubCategories[s])
      //   }
      //   console.log(i,e)
      //   // console.log(a)
      //   for (let a = 0; a < e.length; a++) {
      //     console.log(a)
      //      document.getElementById("cate"+a).style.display  ="none"
   
      //    }
      console.log(document.getElementById("cate"+i),i)
         if(      document.getElementById("cate"+i).style.display  ==="block"){
          console.log("yes")
          document.getElementById("cate"+i).style.display  ="none"
        }
        else{
          console.log("yes1")
  
          document.getElementById("cate"+i).style.display  ="block"
        }
            let a =  []
            SubCategories.map((res1,j)=>{
              if(data === res1.MainCategories){
                a.push(j)
              }
            })
            console.log(a)
        if(a[0] !== i){
            document.getElementById("cate"+a[0]).style.display  ="none"
          }
        // if(i !== 0){
      }
    const setChan2 = (i) =>{
      console.log(i)
       if(      document.getElementById("categp"+i).style.display  ==="block")
      document.getElementById("categp"+i).style.display  ="none"
      else
      document.getElementById("categp"+i).style.display  ="block"
    }
    const setChan3 = (i,data) =>{
      // for (let a = 0; a < SubCategories.length; a++) {
      //  console.log(a)
      //   document.getElementById("catep"+a).style.display  ="none"
    
      // }
      console.log(i)
       if(      document.getElementById("catep"+i).style.display  ==="block")
      document.getElementById("catep"+i).style.display  ="none"
      else
      document.getElementById("catep"+i).style.display  ="block"
    
      let a =  []
      SubCategories.map((res1,j)=>{
        if(data === res1.MainCategories){
          a.push(j)
        }
      })
      console.log(a)
    if(a[0] !== i){
      document.getElementById("catep"+a[0]).style.display  ="none"
    }
    }
    const setChang677 = () =>{
      console.log( document.getElementById("navbar-vertical1"))
      if( document.getElementById("navbar-vertical1").style.display === "none")
      document.getElementById("navbar-vertical1").style.display = "block"
      else
      document.getElementById("navbar-vertical1").style.display = "none"
    }
    const carrouselLeft=()  =>{
      console.log(status,IterationForSlider)

      if(status > 1){
        var carrousel = document.getElementById("carrouselimg" + status);
        carrousel.style.display = "none";
        let a = status - 1
        var carrousel1 = document.getElementById("carrouselimg" + a);
        console.log(carrousel1)
        carrousel1.style.display = "block";
        setstatus(status-1)

      }
else{
  setstatus(IterationForSlider)
}
          }
   
    const carrouselRight=() =>{
      // for (let i = 1; i < 5 ; i++) {
      //   var carrousel = document.getElementById("carrouselimg" + i);
      //   carrousel.style.display = "none";
        
        
      // }
      console.log(status,IterationForSlider)
      if(status < IterationForSlider){
        var carrousel = document.getElementById("carrouselimg" + status);
        carrousel.style.display = "none";
        let a = status + 1
        var carrousel1 = document.getElementById("carrouselimg" + a);
        console.log(carrousel1)
        carrousel1.style.display = "block";
        setstatus(status+1)

      }
else{
  var carrousel = document.getElementById("carrouselimg" + status);
  carrousel.style.display = "none";
  let a = status + 1
  var carrousel1 = document.getElementById("carrouselimg1" );
  console.log(carrousel1)
  carrousel1.style.display = "block";
  setstatus(1)
}

      // let nbCarrousel = 6;
      // let num = 0;
    
      // for (let i = 0; i < nbCarrousel; i++) {
      //     num = i + 1;
      //     var carrousel = document.getElementById("carrouselimg" + num);
      //     if (carrousel.style.display === "none") {
      //       carrousel.style.display = "block";
      //         if (i == 5) {
      //             return carrouselChange(1);
      //         }
      //         return carrouselChange(num + 1);
      //     }
      // }
    }

    // const imageZoom =()=> {
    //   var img, lens, result, cx, cy;
    //   img = document.getElementById("changes-Photo");
    //   result = document.getElementById("changes-Photo");
    //   /*create lens:*/
    //   lens = document.createElement("DIV");
    //   lens.setAttribute("class", "img-zoom-lens");
    //   /*insert lens:*/
    //   img.parentElement.insertBefore(lens, img);
    //   /*calculate the ratio between result DIV and lens:*/
    //   cx = result.offsetWidth / lens.offsetWidth;
    //   cy = result.offsetHeight / lens.offsetHeight;
    //   /*set background properties for the result DIV:*/
    //   result.style.backgroundImage = "url('" + img.src + "')";
    //   result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    //   /*execute a function when someone moves the cursor over the image, or the lens:*/
    //   lens.addEventListener("mousemove", moveLens);
    //   img.addEventListener("mousemove", moveLens);
    //   /*and also for touch screens:*/
    //   lens.addEventListener("touchmove", moveLens);
    //   img.addEventListener("touchmove", moveLens);
    //   function moveLens(e) {
    //     var pos, x, y;
    //     /*prevent any other actions that may occur when moving over the image:*/
    //     e.preventDefault();
    //     /*get the cursor's x and y positions:*/
    //     pos = getCursorPos(e);
    //     /*calculate the position of the lens:*/
    //     x = pos.x - (lens.offsetWidth / 2);
    //     y = pos.y - (lens.offsetHeight / 2);
    //     /*prevent the lens from being positioned outside the image:*/
    //     if (x > img.width - lens.offsetWidth) {x = img.width - lens.offsetWidth;}
    //     if (x < 0) {x = 0;}
    //     if (y > img.height - lens.offsetHeight) {y = img.height - lens.offsetHeight;}
    //     if (y < 0) {y = 0;}
    //     /*set the position of the lens:*/
    //     lens.style.left = x + "px";
    //     lens.style.top = y + "px";
    //     /*display what the lens "sees":*/
    //     result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    //   }
    //   const getCursorPos = (e)=> {
    //     var a, x = 0, y = 0;
    //     e = e || window.event;
    //     /*get the x and y positions of the image:*/
    //     a = img.getBoundingClientRect();
    //     /*calculate the cursor's x and y coordinates, relative to the image:*/
    //     x = e.pageX - a.left;
    //     y = e.pageY - a.top;
    //     /*consider any page scrolling:*/
    //     x = x - window.pageXOffset;
    //     y = y - window.pageYOffset;
    //     return {x : x, y : y};
    //   }
    // }



  //  const handleMouseMove = (e) => {
  //   console.log(e.target.getBoundingClientRect())
  //     const { left, top, width, height } = e.target.getBoundingClientRect()
  //     const x = (e.pageX - left) / width * 100
  //     const y = (e.pageY - top) / height * 100
  //     document.getElementById("carrouselimg1").style.backgroundPosition = `Rs. {x}% Rs. {y}%`
  //   }


    
  const addtocartproduct8 = (productDetail) =>{
    swal("Update!", "Item Save!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Fav")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Fav")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Fav" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Fav" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Fav" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
        
  
  }
  

const addtocartproduct = (productDetail) =>{
  swal("Update!", "Your Service Add in Cart!", "success");

      
  // document.getElementById("myDi").style.visibility = "visible"
  // setTimeout(()=>{
  // document.getElementById("myDi").style.visibility = "hidden"

  // },1000)
  console.log(productDetail)
  if (productDetail[0].Product_Price_Discounted === null){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Product_Price_Discounted){
  const data =  {...productDetail[0] ,
      Percentage : 0,
      DeliveryStatus : "Pending",
      Pieces : 1 ,
      Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id ){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
  return {...item,
      Pieces : 1 + item.Pieces,
      Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
  }
  else{
  console.log("double not match");
  return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else if (productDetail[0].Size_Discounted[0]){
      
          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Size_Discounted[0],
              Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }

  }
  else{




          const data =  {...productDetail[0] ,
              Percentage : 0,
              DeliveryStatus : "Pending",
              Pieces : 1 ,
              Size : productDetail[0].Size[0],
              Product_Price_Discounted : productDetail[0].Price[0],
              Total_Product_Price  : productDetail[0].Price[0] * 1 }
  var data1 = JSON.parse(localStorage.getItem("Cart")) 
  if (data1){
  var data3 = data1.map((item) => {
      if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
          console.log("double");
  ;                   localStorage.setItem("double",JSON.stringify(true))
      return {...item,
          Pieces : 1 + item.Pieces,
          Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
  }
  else{
      console.log("double not match");
      return item
  }

  })
  var data5 =  JSON.parse(localStorage.getItem("double")) 
  console.log(DataPart2.length, data3.length,data5);
  var data10 =  JSON.parse(localStorage.getItem("Cart")) 

  if(data10.length=== data3.length && data5){
  console.log("double remove");
  localStorage.removeItem("double")
  localStorage.setItem("Cart" , JSON.stringify(data3) )

  }
  else{
  console.log("Differet");
  var data2 = [...data1 , data]

  localStorage.setItem("Cart" , JSON.stringify(data2) )
  }
  }
  else{
  console.log("1");
  localStorage.setItem("Cart" , JSON.stringify([data]) )

  }


  }


      

}

    return (
        <>  
     
      {true ?
      // {productDetail &&productDetail[0] && productDetail[0].Product_Name ?
<>
<div className="page-wrapper">

        <main className="main">
          
          <div className="page-content">
            <div className="container">
              <div className="product-details-top mb-2">
                <div className="row">
                  <div className="col-md-6">
                    <div className="product-gallery-vertical">
                      <div className="row">
                        <figure className="product-main-image" >
                        <Carousel
                        swipeable={false}
                        draggable={true}
                        showDots={false}
                        arrows={true}
                        responsive={responsive}
                        infinite={true}
                        minimumTouchDrag={1}
                        autoPlay = {true}
                        transitionDuration={1500}
                      //   autoPlay={this.props.deviceType !== "mobile" ? true : false}
                        autoPlaySpeed={4500}
                        keyBoardControl={true}
                        customTransition="all 2s ease-out"
                        containerClass="carousel-container"
            
                        dotListClass="custom-dot-list-style"

                      >
                        {ImageCha.map((res)=>{
                            
                          return(
                            <img  src={res } alt="product image" />
                          )
                        
                        })
                          
                        }
                        
                        
                              </Carousel>
                      
                        </figure>{/* End .product-main-image */}
                        
                      </div>{/* End .row */}
                    </div>{/* End .product-gallery */}
                  </div>{/* End .col-md-6 */}
                  <div className="col-md-6">
                    <div className="product-details">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav mb-0">
            <div className="d-flex align-items-center" style={{marginBottom :"10px"}}>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="#" style={{color :  "black",fontSize : "12px",padding :  "0px",margin : "0px",fontWeight : "500"}}>Home</a></li>
                <li className="breadcrumb-item"><a href="#" style={{color :  "black",fontSize : "12px",padding :  "0px",margin : "0px",fontWeight : "500"}}>Products</a></li>
                <li className="breadcrumb-item"><a href="#" style={{color :  "red",fontSize : "12px",padding :  "0px",margin : "0px",fontWeight : "500",width:  "190px",  overflow : "hidden",height :  "15px"}}>{productDetail[0] &&productDetail[0].Product_Name}</a></li>
                {/* <li className="breadcrumb-item active" aria-current="page">Centered</li> */}
              </ol>
              {/* <nav className="product-pager ml-auto" aria-label="Product">
                <a className="product-pager-link product-pager-prev" href="#" aria-label="Previous" tabIndex={-1}>
                  <i className="icon-angle-left" />
                  <span>Prev</span>
                </a>
                <a className="product-pager-link product-pager-next" href="#" aria-label="Next" tabIndex={-1}>
                  <span>Next</span>
                  <i className="icon-angle-right" />
                </a>
              </nav> */}
              {/* End .pager-nav */}
            </div>{/* End .container */}
          </nav>{/* End .breadcrumb-nav */}
                      <h2 className="product-title" style={{fontSize : "20px",marginBottom : "0px"}}>  {productDetail[0] &&productDetail[0].Product_Name}  Rent in Dubai</h2>{/* End .product-title */}
                     
                      {
                                                                                                  
                         productDetail[0] && productDetail[0].Product_Price_Discounted ? 
                                                 
                      <div class="product-price"  style={{justifyContent :"flex-start"}}>
                          <span class="new-price" style={{fontSize : "19px"}}>Rs. {productDetail[0] && (productDetail[0].Product_Price_Discounted)}</span>
                          <span class="old-price" style={{fontSize : "19px"}}>Rs. {productDetail[0] && (productDetail[0].Product_Price)}</span>
                      </div>
                      
                      : 

                      <div class="product-price" style={{fontSize : "19px",justifyContent :"flex-start"}}  >
                                        Rs. {productDetail[0] && (productDetail[0].Product_Price)} 
                      </div>
                   
                   }
                      
                      {/* <div className="product-content">
                        <p style={{color : "black",fontSize:"17px"}}>{productDetail[0] &&productDetail[0].Product_Stock_Qty > 0 ? "In Stock" :  "Out Of Stock"}</p>
                      </div> */}
                      
                      {/* End .product-price */}
                      <div className="product-content">
                        <p style={{color : "black"}}>{productDetail[0] &&productDetail[0].Product_Short_Notes}</p>
                      </div>{/* End .product-content */}
                      
                      {/* <div className="details-filter-row details-row-size">
                        <label htmlFor="size">Size:</label>
                        <div className="select-custom">
                          <select name="size" id="size" className="form-control">
                            <option value="#" selected="selected">One Size</option>
                            <option value="s">Small</option>
                            <option value="m">Medium</option>
                            <option value="l">Large</option>
                            <option value="xl">Extra Large</option>
                          </select>
                        </div>
                        <a href="#" className="size-guide"><i className="icon-th-list" />size guide</a>
                      </div> */}
                      <div className="product-details-action">
                        <div className="details-action-col">

                       <div class="product-details-quantity">
                                                
                                     <b> Days :   </b>         <div class="input-group input-spinner"><div class="input-group-prepend"><button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button" onClick = {()=>minus()} ><i style={{color : "red"}} class="icon-minus" ></i></button></div><input type="text" style={{textAlign: "center"}} class="form-control " required placeholder=""   value={Price} onChange={(e)=>setPrice(e.target.value)}/><div class="input-group-append"><button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button" onClick={()=>plus()}><i style={{color : "red"}} class="icon-plus"> </i></button></div></div>

                                            </div>
                          {/* <div className="product-details-quantity">
                          <div class="input-group-prepend"><button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button></div>
                            <input type="number" id="qty" className="form-control" defaultValue={1} min={1} max={10} step={1} data-decimals={0} required />
                            <div class="input-group-append"><button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button></div>
                          </div> */}
                          {/* End .product-details-quantity */}
                
                          </div>{/* End .details-action-col */}


                          {productDetail[0] ? productDetail[0].Product_Stock_Qty > 0 ?
                         <>
                         <span  onClick={()=>sdfjkfkjfd()} style={{width:  "100%"}}>
                          <span className="btn-hover-change"><span><ShoppingCartIcon   style={{padding:"0px 0px" , fontSize : "20px",color : "#d60620"}} /> Booking Now</span></span>
  </span>   
                        <div className="details-action-wrapper" style={{paddingTop :"20px"}}>
                          <span style={{marginRight : "30px"}} className="btn-product btn-wishlist btn-hover-change1" title="Wishlist" onClick={()=>addtocartproduct8([productDetail[0]])}><span>Add to Wishlist</span></span>
                          {/* <a href="#" className="btn-product btn-compare" title="Compare"><span>Add to Compare</span></a> */}
                       
                        </div>
                         </>
                          : "Out Of Stock "
                          : ""
                        }



                          {/* <span  onClick={()=>sdfjkfkjfd()} style={{width:  "100%"}}>
                          <span className="btn-hover-change"><span><ShoppingCartIcon   style={{padding:"0px 0px" , fontSize : "20px",color : "#d60620"}} /> Add to Cart</span></span>
  </span>   
                   
 
                        <div className="details-action-wrapper" style={{paddingTop :"20px"}}>
                          <span style={{marginRight : "30px"}} className="btn-product btn-wishlist btn-hover-change1" title="Wishlist" onClick={()=>addtocartproduct8([productDetail[0]])}><span>Add to Wishlist</span></span>
                       
                        </div> */}
                        {/* End .details-action-wrapper */}
                      </div>{/* End .product-details-action */}
                      <div className="ratings-container" style={{alignItems: "center"}}>
    
    <div className="ratings">
      <div className="ratings-val" style={{width: allproductReview.length === 0 ? "0%" : allproductReview[0].rate*20+'%'}} />{/* End .ratings-val */}
    </div>{/* End .ratings */}
    <a className="ratings-text" href="#product-review-link" id="review-link">(  {allproductReview.length === 0 ?  "No " :  allproductReview.length} Reviews )</a>
  </div>{/* End .rating-container */}
                      <div className="product-details-footer">
                        <div className="product-cat">
                          <span>Category:</span>
                          <a href="#">{productDetail[0] &&productDetail[0].Product_Catagories} , </a> 
                                                   {productDetail[0] &&productDetail[0].Product_Sub_Catagories ?
                          <a href="#">{productDetail[0] &&productDetail[0].Product_Sub_Catagories} , </a>
                            : ""

                          }
                          {productDetail[0] &&productDetail[0].Course_Sub_Catagories ?
                          <a href="#">{productDetail[0] &&productDetail[0].Course_Sub_Catagories}</a>
                            : ""

                          }
                        </div>{/* End .product-cat */}
                       
                      </div>{/* End .product-details-footer */}
                    </div>{/* End .product-details */}
                  </div>{/* End .col-md-6 */}
                </div>{/* End .row */}
              </div>{/* End .product-details-top */}




     













              <div className="product-details-tab">
                <ul className="nav nav-pills justify-content-center" role="tablist">
                  <li className="nav-item">
                    <a className="nav-link active" id="product-desc-link" data-toggle="tab" href="#product-desc-tab" role="tab" aria-controls="product-desc-tab" aria-selected="true"   >Description</a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" id="product-info-link" data-toggle="tab" href="#product-info-tab" role="tab" aria-controls="product-info-tab" aria-selected="false">Additional information</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="product-shipping-link" data-toggle="tab" href="#product-shipping-tab" role="tab" aria-controls="product-shipping-tab" aria-selected="false">Shipping &amp; Returns</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="product-review-link" data-toggle="tab" href="#product-review-tab" role="tab" aria-controls="product-review-tab" aria-selected="false">Reviews (2)</a>
                  </li> */}
                </ul>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="product-desc-tab" role="tabpanel" aria-labelledby="product-desc-link">
                    <div className="product-desc-content">
                      <h3>Product Information</h3>


                      {productDetail[0] && productDetail[0].videoUrl ?
                  <video controls src={productDetail[0].videoUrl}  style={{maxHeight : "300px"}}></video>
                  : ""
                 }

                  {productDetail[0] &&productDetail[0].Product_Long_Notes && productDetail[0].Product_Long_Notes[0]=== "<" ?
                    <p style={{maxWidth : "100%",overflow:"scroll"}}> {productDetail[0] &&productDetail[0].Product_Long_Notes ?<div dangerouslySetInnerHTML={{__html: productDetail[0].Product_Long_Notes}} />:""}</p> 
                    :
                    <p style={{maxWidth : "100%",overflow:"scroll"}}> {productDetail[0] &&productDetail[0].Product_Long_Notes ? productDetail[0].Product_Long_Notes : ""}</p>
                  }
                   
                    </div>{/* End .product-desc-content */}
                  </div>{/* .End .tab-pane */}
                  <hr />
                  <div>
                    <div className="reviews" style={{padding: "10px 5px"}}>
                      <h3>Reviews ({allproductReview.length})</h3>
                      {
                      // productDetail[0] && productDetail[0].Review &&
                      allproductReview.length > 0 ?
                       allproductReview.map((res3)=>{
                       
                            return(
                          <div className="review">
                        <div className="row no-gutters">
                          <div className="col-auto">
                            <img src={logo1} alt="" style={{width: "68px" , height: '60px' , borderRadius : "40px",marginLeft : "10px"}} />
                            
                            
                          </div>
                          <div className="col" style={{minHeight : "auto",padding: "0px 0px 0px 10px"}}>
                            {/* <h4>Good, perfect size</h4> */}
                            <h4 style={{margin : "0px",padding  :"0px"}}><a href="#">{res3.name}</a></h4>
                            <div className="ratings-container">
                              <div className="ratings">
                                <div className="ratings-val" style={{width: res3.rate*20+'%'}} />
                              </div>

                            </div>
                            <div className="review-content">
                              <h6>{res3.review_message}</h6>
                            </div>
                            {/* <div className="review-action">
                              <a href="#"><i className="icon-thumbs-up" />Helpful (2)</a>
                              <a href="#"><i className="icon-thumbs-down" />Unhelpful (0)</a>
                            </div> */}
                          </div>
                        </div>
                      </div>
                        )
                         
                        
                       })
                       : 
                       <h4><center>No Reviews</center></h4>
                      }
                      
                    </div>{/* End .reviews */}
                    <div style={{padding  : "20px 40px"}}>
                                   <h5 style={{color : "black"}}>You're reviewing:</h5>
                                   {/* <br /> */}
                                    <h6>
                                    {
                                            productDetail[0] ? 
                                            productDetail[0].Product_Title
                                            : " "
                                        }                                        </h6>
                                    <p style={{color : "black"}}>Your Rating <span className="text-danger">*</span></p>
                                    <div id="Rate">
                                        <p style={{color : "black"}}>Rate <GradeIcon onClick={()=>RatingProduct1(1)} className="Icon-start a1-start"/><GradeIcon onClick={()=>RatingProduct1(2)}  className="Icon-start a2-start"/><GradeIcon onClick={()=>RatingProduct1(3)}  className="Icon-start a3-start"/><GradeIcon onClick={()=>RatingProduct1(4)}  className="Icon-start a4-start"/><GradeIcon onClick={()=>RatingProduct1(5)}  className="Icon-start a5-start"/></p>
                                        {/* <p style={{color : "black"}}>Value  <GradeIcon  onClick={()=>RatingProduct2(1)}  className="Icon-start a1-start"/><GradeIcon  onClick={()=>RatingProduct2(2)}   className="Icon-start a2-start"/><GradeIcon  onClick={()=>RatingProduct2(3)}   className="Icon-start a3-start"/><GradeIcon  onClick={()=>RatingProduct2(4)}   className="Icon-start a4-start"/><GradeIcon  onClick={()=>RatingProduct2(5)}   className="Icon-start a5-start"/></p>
                                        <p style={{color : "black"}}>Price  <GradeIcon  onClick={()=>RatingProduct3(1)}  className="Icon-start a1-start"/><GradeIcon  onClick={()=>RatingProduct3(2)}   className="Icon-start a2-start"/><GradeIcon  onClick={()=>RatingProduct3(3)}   className="Icon-start a3-start"/><GradeIcon  onClick={()=>RatingProduct3(4)}   className="Icon-start a4-start"/><GradeIcon  onClick={()=>RatingProduct3(5)}   className="Icon-start a5-start"/></p> */}
                                    </div>
                               </div>
                               
                               <div className="col-md-12">

                                    <form onSubmit={(e)=>submitUserReview(e)}>

                                        <div className="p-3 p-lg-5 border">
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label htmlFor="c_fname" className="text-black">Full Name <span className="text-danger">*</span></label>
                                                    <input type="text" value={name} onChange={(e)=>setname(e.target.value)}   className="form-control" placeholder='Your Name'/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-md-6">
                                                    <label htmlFor="c_message" className="text-black">Review <span className="text-danger">*</span></label>
                                                    <textarea value={Message} onChange={(e)=>setMessage(e.target.value)}   cols="30" rows="7" className="form-control"  placeholder='Your Review For this Product'></textarea>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 ">
                                                    <input type="submit" className="btn btn-primary btn-lg btn-block" value="Send Reviews" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    </div>
                  </div>{/* .End .tab-pane */}
                </div>{/* End .tab-content */}
              </div>{/* End .product-details-tab */}



              
                             
              <h2 className="title text-center mb-4">Today's for Rent</h2>{/* End .title text-center */}
            

              <Carousel2 />








            </div>{/* End .container */}
          </div>{/* End .page-content */}
        </main>{/* End .main */}
      </div>
      </>
:
<Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      }
    
        </>
    )
}

export default SubCategoriesProductDetails;
