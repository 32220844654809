import React, { useState , useEffect } from 'react';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';

const Contact = (props)=>  {


    useEffect(() => {
        window.scrollTo(0, 0)

    }, []);




        return (
            <div>
                <div className="bg-light py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mb-0"><Link to="/">Home</Link> <span className="mx-2 mb-0">/</span> <strong className="text-black">Rental Requirements</strong></div>
                        </div>
                    </div>
                </div>
                <div className="container" style={{width : "99% " ,padding : "30px 30px"}}>
                        <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{fontWeight :"bold"}}>
                                {/* <h2>This Privacy Policy</h2> */}
                                <p>Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models like KIA, Hyundai, and MG. At Rent a Car in, we’re committed to delivering an unparalleled experience of comfort, convenience, and excellence.</p>

<h3>Rental Requirements</h3>
<p><strong>Driver’s License:</strong> Pakistani residents must present a valid Pakistani driving license held for at least 12 months. Tourists can drive with a valid foreign license from eligible countries or an International Driver’s Permit (IDP).</p>

<p><strong>Passport Information:</strong> Pakistani residents need to provide a copy of their passport and visa page. Tourists must provide a valid Pakistani entry visa along with their passport.</p>

<p><strong>Method of Payment:</strong> A valid credit card in the renter’s name is required. Debit cards can be used for payments but not for security blocking. All major credit cards are accepted.</p>

<h3>Book Now</h3>
<p>Elevate your travel experience with Rent a Car in Karachi! Book with us today and enjoy top-tier transportation solutions.</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        )
    
}


export default Contact;