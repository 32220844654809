import React ,{useState,useEffect}from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
// import Shop from './Pages/Shop';
import Contact from './Pages/Contact';
// import Detail from './Pages/Detail';



import rentacarinkarachi from './Pages/rentacarinkarachi';
import rentacarkarachi from './Pages/rentacarkarachi';
import rentacarserviceinkarachi from './Pages/rentacarserviceinkarachi';
import BulletProofVehiclesAtCarRentalsPakistan from './Pages/Bullet Proof Vehicles At Car Rentals Pakistan';
import carrentalinkarachi from './Pages/carrentalinkarachi';
import carrentalkarachi from './Pages/carrentalkarachi';





import Card from './Pages/Card';
import Checkout from './Pages/Checkout';
import Checkout2 from './Pages/checkout2';
import Categories from './Pages/Categories';
import Categories1 from './Pages/Categories1';
// import Thankyou from './Pages/Thankyou';
import Header from './components/Header';
import Footer from './components/Footer';

// import demo from './Pages/demo';
// import CategoriesShop from './Pages/CategoriesShop';
import SearchData from './Pages/SearchData';

// import Signup from './Pages/Admin/Signup';
// import EditCard from "./Pages/EditCart"
// import CategoriesBook from './Pages/CategoriesBook';
// import CategoriesBags from './Pages/CategoriesBags';
// import CategoriesShoes from './Pages/CategoriesShoes';
// import ShoesProduct from './Pages/ShoesProduct';
// import ShoesProductDetails from './Pages/ShoesProductDetails';
// import SchoolClassProduct from './Pages/SchoolClassProduct';
// import BagsClassProduct from './Pages/BagsClassProduct';
// import chat from './Pages/chat';
// import SignupOtp from './Pages/SignupOtp';


// import whatapps from './assets/images/what.png';






// import SubCategories from './Pages/Categories';
// import SubCategoriesProduct from './Pages/SubCategoriesProduct';












import Privacy from './Pages/Privacy';
import consign from './Pages/consign';
// import Shipping from './Pages/Shipping';
import tracking from './Pages/tracking';
import FAQ from './Pages/FAQ';
import Shop from './Pages/Shop';
// import SaleSubCategoriesProduct from './Pages/SaleSubCategoriesProduct';
// import menSubCategoriesProduct from './Pages/menSubCategoriesProduct';
// import womenSubCategoriesProduct from './Pages/womenSubCategoriesProduct';
import SubCategoriesProduct from './Pages/SubCategoriesProduct';
import SubCategoriesProductDetails from './Pages/SubCategoriesProductDetails';
import SubCategoriesProductDetails1 from './Pages/SubCategoriesProductDetails1';
import SubCategoriesProductDetails2 from './Pages/SubCategoriesProductDetails2';
import SizeProductDetail from './Pages/SizeProductDetail';
import thankyou from './Pages/Thankyou';
import chat from './Pages/chat';
// import WomenOnlySubCategoriesProduct from './Pages/WomenOnlySubCategoriesProduct';
// import MenOnlySubCategoriesProduct from './Pages/MenOnlySubCategoriesProduct';
import SubCategoriesProduct1 from './Pages/SubCategoriesProduct1';
import SubCategoriesProduct2 from './Pages/SubCategoriesProduct2';
import Favorite from './Pages/Favorite';
import whislist from './Pages/whislist';
// import CategoriesUniform from './Pages/CategoriesUniform';
// import UniformClassProduct from './Pages/UniformClassProduct';
// import UniformProductDetails from './Pages/UniformProductDetails';
// import SchoolBookProductDetail from './Pages/SchoolBookProductDetail';
// import BagsProductDetail from './Pages/BagsProductDetail';
// import ArrivalCategoriesProduct from './Pages/ArrivalCategoriesProduct';

import whatapps from './assets/images/what.png';

// import teacherform from './Pages/teacherform';
// import studentform from './Pages/studentform';
import Shipping from './Pages/Shipping';
import Term from './Pages/Term';
import ab from './Pages/ab';
import expertpick from './Pages/expertpick';
import recommendedproduct from './Pages/recommendedproduct';
import newArrival from './Pages/newArrival';
import Error from './Pages/Error';
import CorporateEquiries from './Pages/CorporateEquiries';
import Trafficfines from './Pages/Trafficfines';
import testimonials from './Pages/testimonials';





import rentacarIslamabad from './Pages/rentacarIslamabad';
import rentacarJhelum from './Pages/rentacarJhelum';
import rentacarLahore from './Pages/rentacarLahore';
import rentacarMultan from './Pages/rentacarMultan';

import rentacarinkarachiwithdriver from './Pages/rentacarinkarachiwithdriver';
import rentacarPeshawar from './Pages/rentacarPeshawar';
import rentacarHyderabad from './Pages/rentacarHyderabad';



import rentacarKarachiPakistan from './Pages/rentacarKarachi-Pakistan';

import rentacarWedding from './Pages/rentacarWedding-Rent-A-Car';


import rentacarPakistan from './Pages/rentacarPakistan';

import rentacarGulistaneJohar from './Pages/rentacarGulistan-e-Johar';



import rentacar11 from './Pages/rentClifton';
import rentacar12 from './Pages/rentDolmen City';
import rentacar13 from './Pages/rentEmpress Market';
import rentacar14 from './Pages/rentGulberg';
import rentacar15 from './Pages/rentGulzar-e-Hijri';
import rentacar16 from './Pages/rentLiaquatabad';
import rentacar17 from './Pages/rentLucky One Mall';
import rentacar18 from './Pages/rentM.A. Jinnah Road';
import rentacar19 from './Pages/rentNew Karachi';
import rentacar20 from './Pages/rentNazimabad';
import rentacar21 from './Pages/rentNorth Nazimabad';
import rentacar22 from './Pages/rentPort Grand';
import rentacar23 from './Pages/rentQuaid e Azam Museum';
import rentacar24 from './Pages/rentTurtle Beach';
import rentacar25 from './Pages/rentkarachi';






import Montly from './Pages/montly';
import Weekly from './Pages/weekly';
// import submain from './Pages/submain';

// import Privacy from './Pages/Privacy';
// import MainUserSignup from './Pages/MainUserSignup';
// import MainUserLogin from './Pages/MainUserLogin';
// import forgetpassaccount from './Pages/forgetpassaccount';


// import help from './Pages/help';
// import userDashboard from './Pages/userDashboard';
// import EditNameAddress from './Pages/EditNameAddress';
// import userDashOrderDetails from './Pages/userDashOrderDetails';


// import SchoolCategories from './Pages/SchoolCategories';
// import SchoolClassesCategories from './Pages/SchoolClassesCategories';
// import SchoolClassesCategoriesProduct from './Pages/SchoolClassesCategoriesProduct';
// import SchoolClassesCategoriesProductDetail from './Pages/SchoolClassesCategoriesProductDetail';


// import $ from 'jquery';


// import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css";
import "./bootstrap.min.css"
import "./all.css"
import "./custom.css"
import "./bootsnav.css"
import "./responsive.css"
import "./style1.css"
import "./css/style.css";
import "./lib/owlcarousel/assets/owl.carousel.min.css";

import "./css1/custom.css";
import "./css1/responsive.css";
import "./css1/bootstrap-select.css";
import "./css1/baguetteBox.min.css";
import "./css1/owl.carousel.min.css";
import "./css1/bootsnav.css";
import "./css1/code_animate.css";
import "./css1/carousel-ticker.css";
import "./css1/superslides.css";
import "./css1/all.css";
import "./css1/style.css";

import "./css1/bootsnav";

import "./asset1/vendor/line-awesome/line-awesome/line-awesome/css/line-awesome.min.css";
import "./asset1/css/bootstrap.min.css";
// import "./asset1/css/plugins/owl-carousel/owl.carousel.css";
import "./asset1/css/plugins/jquery.countdown.css";
import "./asset1/css/plugins/magnific-popup/magnific-popup.css";
import "./asset1/css/style.css";
import "./asset1/css/skins/skin-demo-26.css";
import "./asset1/css/demos/demo-26.css";


import Navbar from "../src/components/Navbar";
import "../src/dist/styles.css";
import "./style6.css"

// import Footer from "../components/Footer";

import whatapps1 from './assets/images/png.png';
import car1 from './assets/images/car1.png';


function App() {
  
  const [showPopup, setShowPopup] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [photo, setphoto] = useState([car1]);


  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setCurrentIndex((currentIndex + 1) % 3);
  //   }, 2000); // 3 seconds

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [currentIndex,photo]);


  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="App" >  
         <a href="tel:+923182333057" target="_blank"><img src={whatapps1} alt="" style={{position : "fixed" ,zIndex : "999999", top : "80%" , right : "15px",width:"50px",height : "50px"}}/></a>
                  
     <a href="https://wa.me/923182333057?text= ہائے مجھے کار رینٹل کے بارے میں مزید معلومات درکار ہیں۔ https://rentacarinkarachi.com" target="_blank"><img src={whatapps} alt="" style={{position : "fixed" ,zIndex : "999999", top : "72%" , right : "10px",width:"60px",height : "60px"}}/></a>





     {showPopup ?
<a href="tel:+923182333057">
        <div className="popup-container">
      <div className="popup-content">
        <img src={photo[currentIndex]} alt="Popup Image" style={{margin:"20px auto 0px auto"}}/>
        <span className="close-icon" style={{


          
        }} onClick={()=>handleClosePopup(!showPopup)}>&times;</span>
      </div>
    </div>

</a>
:""

}











      <Router>

        {/* <Header /> */}
        <Navbar />
        
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/term-policy' component={Term} />
          <Route exact path='/Shipping' component={Shipping} />
          <Route exact path='/Corporate-Equiries' component={CorporateEquiries} />
          {/* <Route exact path='/Text' component={Text} /> */}
          <Route exact path='/product/new-arrival' component={newArrival} />
          <Route exact path='/product/recommended-product' component={recommendedproduct} />
          <Route exact path='/product/expert-pick' component={expertpick} />



          <Route exact path='/rent-a-car-Wedding-Rent-A-Car' component={rentacarWedding} />
          <Route exact path='/rent-a-car-Karachi-Pakistan' component={rentacarKarachiPakistan} />
       

          <Route exact path='/rent-a-car-Pakistan' component={rentacarPakistan} />
          
          <Route exact path='/rent-a-car-Gulistan-e-Johar' component={rentacarGulistaneJohar} />

          <Route exact path='/rent-a-car-Islamabad' component={rentacarIslamabad} />
          <Route exact path='/rent-a-car-airport-pick-up &-drop-off' component={rentacarJhelum} />
          <Route exact path='/rent-a-car-Hyderabad' component={rentacarHyderabad} />
          <Route exact path='/rent-a-car-Lahore' component={rentacarLahore} />
          <Route exact path='/rent-a-car-Multan' component={rentacarMultan} />
          <Route exact path='/rent-a-car-Peshawar' component={rentacarPeshawar} />


          <Route exact path='/rent-a-car-in-clifton' component={rentacar11} />
          <Route exact path='/rent-a-car-in-dolmen-city' component={rentacar12} />
          <Route exact path='/rent-a-car-in-empress-market' component={rentacar13} />
          <Route exact path='/rent-a-car-in-gulberg' component={rentacar14} />
          <Route exact path='/rent-a-car-in-gulzar-e-hijri' component={rentacar15} />
          <Route exact path='/rent-a-car-in-liaquatabad' component={rentacar16} />
          <Route exact path='/rent-a-car-in-lucky-one-mall' component={rentacar17} />
          <Route exact path='/rent-a-car-in-m-a-jinnah-road' component={rentacar18} />
          <Route exact path='/rent-a-car-in-new-karachi' component={rentacar19} />
          <Route exact path='/rent-a-car-in-nazimabad' component={rentacar20} />
          <Route exact path='/rent-a-car-in-north-nazimabad' component={rentacar21} />
          <Route exact path='/rent-a-car-in-port-grand' component={rentacar22} />
          <Route exact path='/rent-a-car-in-quaid-e-azam-museum' component={rentacar23} />
          <Route exact path='/rent-a-car-in-turtle-beach' component={rentacar24} />
          <Route exact path='/rentacarkarachi' component={rentacar25} />



          <Route exact path='/Bullet-Proof-Vehicles-At-Car-Rentals-karachi-Pakistan' component={BulletProofVehiclesAtCarRentalsPakistan} />
          <Route exact path='/rent-a-car-in-karachi' component={rentacarinkarachi} />
          <Route exact path='/rent-a-car-in-karachi-with-driver' component={rentacarinkarachiwithdriver} />
          <Route exact path='/rent-a-car-karachi' component={rentacarkarachi} />
          <Route exact path='/rent-a-car-service-in-karachi' component={rentacarserviceinkarachi} />
          <Route exact path='/car-rental-in-karachi' component={carrentalinkarachi} />
          <Route exact path='/car-rental-karachi' component={carrentalkarachi} />
     
          <Route exact path='/shop-all' component={ab} />
          <Route exact path='/privacy' component={Privacy} />
          {/* <Route exact path='/traffic-fines' component={Trafficfines} /> */}
          <Route exact path='/favorite' component={Favorite} />
          <Route exact path='/whislist' component={whislist} />
          <Route exact path='/thankyou' component={thankyou} />
          <Route exact path='/consign' component={consign} />
          <Route exact path='/checkout' component={Checkout} />
          <Route exact path='/checkout2' component={Checkout2} />
          <Route exact path='/FAQ' component={FAQ} />
          <Route exact path='/chat' component={chat} />
          <Route exact path='/cart' component={Card} />
          <Route exact path='/all-shop' component={Shop} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/about' component={About} />
          <Route exact path='/track' component={tracking} />
          <Route exact path='/testimonials' component={testimonials} />
          <Route exact path='/search/product-item' component={SearchData} />
          {/* <Route exact path='/ab' component={SubCategoriesProduct} /> */}
          {/* <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct} /> */}
          <Route exact path='/main-categories/:categoriesName' component={Categories} />
          {/* <Route exact path='/main-categories/:categoriesName' component={Categories} /> */}
          <Route exact path='/main-categories-car-rental/:categoriesName' component={Categories1} />
          <Route exact path='/categories/:categoriesName/all-products' component={SubCategoriesProduct2} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products' component={SubCategoriesProduct1} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products' component={SubCategoriesProduct} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SubCategoriesProductDetails} />
          <Route exact path='/categories/:categoriesName/:categoriesName1/all-products/:productName/:productId' component={SubCategoriesProductDetails2} />
          <Route exact path='/categories/:categoriesName/all-products/:productName/:productId' component={SubCategoriesProductDetails1} />
          <Route exact path='/categories-montly/:categoriesName/all-products/:productName/:productId' component={Montly} />
          <Route exact path='/categories-weekly/:categoriesName/all-products/:productName/:productId' component={Weekly} />
          {/* <Route exact path='/main-categories/:categoriesName/all-products/:productName/:productId' component={submain} /> */}
          <Route exact path='/main-categories/:categoriesName/:categoriesName1/:categoriesName2/all-products/:productName/:productId' component={SizeProductDetail} />

          <Route path='*' component={Error} />

        </Switch>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
