import SelectCar from "../images/plan/icon1.png";
import Contact from "../images/plan/icon2.png";
import Drive from "../images/plan/icon3.png";
import { Link  ,useHistory } from 'react-router-dom';

function PlanTrip(props) {

  let history = useHistory();


  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h3>Plan your trip now</h3>
              <h2>Quick & easy car rental</h2>
            </div>

            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box" onClick={()=>history.push("/categories/Cars/all-products")} style={{cursor:"pointer"}}>
                <center>
                <img src={SelectCar} alt="icon_img" />
                <h3>Select Car</h3>
                <p>
                  We offer* a big range of vehicles for all your driving needs.
                  We have the perfect car to meet your needs
                </p>

                </center>
              </div>

              <div className="plan-container__boxes__box" onClick={()=>history.push("/contact")}  style={{cursor:"pointer"}}>
                <center>

                <img src={Contact} alt="icon_img" />
                <h3>Contact Operator</h3>
                <p>
                  Our knowledgeable and friendly operators are always ready to
                  help with any questions or concerns
                </p>
                </center>
              </div>

              <div className="plan-container__boxes__box">
                <center>

                <img src={Drive} alt="icon_img" />
                <h3>Let's Drive</h3>
                <p>
                  Whether you're hitting the open road, we've got you covered
                  with our wide range of cars
                </p>
                </center>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PlanTrip;
