import React , {useState , useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';
import swal from 'sweetalert';


import logo1 from '../assets/images/rsz_logo-3.png';
// import logo1 from '../assets/images/apple-touch-icon.png';
// import Head1 from "../assets/images/header-bg-rpt.jpg"
// import SearchIcon from '@material-ui/icons/Search';
// import ClearIcon from '@material-ui/icons/Clear';
// import MenuIcon from '@material-ui/icons/Menu';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
// // import AddAlertIcon from '@mui/icons-material/AddAlert';
// import SendIcon from '@material-ui/icons/Send';
// import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import { useSelector, useDispatch } from 'react-redux';

import Loader from "react-loader-spinner";



const Header =(props)=> {

  const [total1 , settotal1] = useState(0)
    const [search , setSearch] = useState("")
    const [DataPart2 , setDataPart2] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    // const [menu1 , setmenu1] = useState(false)
    // const [adminHeader , setadminHeader] = useState(false)
    // const [displayMenu , setdisplayMenu] = useState(false)
    // const [MainLine , setMainLine] = useState("")
    // const [MainForum , setMainForum] = useState(false)
    const [MainCatogories , setMainCategories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [fvalue , setfvalue] = useState([])
    // const [fvalue1 , setfvalue1] = useState([])
    // const [fg , setfg] = useState(true)
    // const [abc , setabc] = useState(false)
    const [DisplaySearch , setDisplaySearch] = useState(false)
    // const [DisplayMenu , setDisplayMenu] = useState(false)
    // const [bht , setbht] = useState("")
    const [mainUser , setmainUser] = useState({})
    // const [couponShow1 , setcouponShow1] = useState(false)
    // const [name, setname] = useState("") 
    // const [email, setemail] = useState("") 
    // const [message, setmessage] = useState("") 
    // const [photo, setphoto] = useState("") 
    // const [allmessage , setallmessage] = useState([])
    const [cart1 , setcart1] = useState([])
    const [fav1 , setfav1] = useState([])
    // const [photo, setphoto] = useState("") 

    let history = useHistory();


    useEffect(() =>{
      document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
      document.getElementById("menu-mobile-1").style.visibility = "hidden"
      document.getElementById("menu-mobile-2").style.visibility = "hidden"
      var data1 =  JSON.parse(localStorage.getItem("Cart")) ?  JSON.parse(localStorage.getItem("Cart"))  : []
      var data2 =  JSON.parse(localStorage.getItem("Fav")) ?  JSON.parse(localStorage.getItem("Fav")) : []
      setcart1(data1)
      setfav1(data2)
      // let data5 =  JSON.parse(localStorage.getItem("CartPrice")) 
      // settotal1(data5)
      let j = 0 
      data1.map((item,i)=>{
        j = item.Total_Product_Price + j
     })
     settotal1(j)
        if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))
        
        // fetch("https://pappu-prado-main-back.vercel.app/Forum",{
       
        fetch("https://pappu-prado-main-back.vercel.app/CourseAllSubCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            let data = res3.sort((a, b) => {
                return a.sort - b.sort;
            });
            console.log(data);
            setSubClassCategories(data)
        })
        fetch("https://pappu-prado-main-back.vercel.app/AllCategories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            // let dat1 = []
            // res3.map((res55,c)=>{
            //     dat1.push(0)
            // })
            // setfvalue1(dat1)
            // let data = res3.sort((a, b) => {
            //     return a.sort - b.sort;
            // });
            let unique_values = res3
        .map((item) => item.CategoriesName)
        .filter(
            (value, index, current_value) => current_value.indexOf(value) === index
        );
            setSubCategories(unique_values)        
        }) 

        fetch("https://pappu-prado-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res3=>res3.json())
        .then(res4=>{
            let dat = []
            let dat1 = []
            res4.map((res55,c)=>{
                dat.push(res55)
                dat1.push(0)
            })
                        console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCategories(data)
            console.log(data)
            setfvalue(dat1)
        })
     
        const inter = setInterval(()=>{
     
            var data1 =  JSON.parse(localStorage.getItem("Cart")) 
         if (data1) {
             setDataPart2(data1)
             setcart1(data1)
            //  const data = DataPart2.length  
            //  setDataPart2(data)
            }
         else setDataPart2(0)
            var data6 =  JSON.parse(localStorage.getItem("Fav")) 
         if (data6) {
             setfav1(data6)
            //  const data = DataPart2.length  
            //  setfav1(data)
            }
         else setfav1(0)

         if( JSON.parse(localStorage.getItem("user main")) ) setmainUser(JSON.parse(localStorage.getItem("user main")))
        
       

        },3000)
        
        return () => clearInterval(inter);
    },[])
  

    const changeFruit = (goto1) => {
      // setCurrentFruit(newFruit)
      // history.push(goto);
      window.location = goto1;
    }
    

    const SubmitData45342 = (e) =>{
      e.preventDefault()
      console.log("df")
      setDisplaySearch(true)
      localStorage.removeItem("SearchData")
          fetch("https://pappu-prado-main-back.vercel.app/SearchProduct",{
                                  method: "POST",
                                  headers :  {
                                      "Content-Type" : "application/json" , 
                                  } ,
                                  body : JSON.stringify({
                                      search : search.toLowerCase() ,
      
                                  })
                              })
                              .then(res=>res.json())
                              .then((res1)=>{ 
                                
                                console.log(res1) 
                                  const SearchProductItem = []
                                  
                                  let search1 = search.split(" ")
                                res1.map((res2,i)=>{
                                    // console.log(res2.Product_Name.toLowerCase().split(" "),res2.Product_Title.toLowerCase().split(" "),i)
                                    var Name = res2.Product_Name.toLowerCase().split(" ")
                                     var Title = res2.Product_Title.toLowerCase().split(" ")
                                    // console.log(Name , Title)
                                    for (let j = 0; j < search1.length; j++) {
                                      
                                      Name.map((res3,i)=>{
  
                                          if (res3 === search1[j].toLowerCase().trim()){
                                              SearchProductItem.unshift(res2)
                                          }
                                      })
                                      Title.map((res4,i)=>{
                                        if (res4 === search1[j].toLowerCase().trim()){
                                          SearchProductItem.unshift(res2)
                                        }
                                      })
                                    }
                                })
                                  console.log(SearchProductItem)    

                                  console.log(SearchProductItem,props)
                                  let uniq = [...new Set(SearchProductItem)];
      
                                  localStorage.setItem("SearchData" , JSON.stringify(uniq) )
                                  
                                  setDisplaySearch(false)
                                  setTimeout(()=>{
                                      history.push("/search/product-item");
      
                                      // return <Redirect push to="/shop/search/product-item" /> 
                                      // props.history.push(`/shop/search/product-item`)
                                  },1000)   
                                  // localStorage.setItem("SearchData" , JSON.stringify(res1) )
                                  // props.history.push(`/shop/search/product-item`)
      
                                  
                          })
                          .catch(err=>{
                            console.log(err)
                            swal("There is An Error")                  
                            setDisplaySearch(false)
                              }) 
      }

const ChangeCateUrl = (res) =>{
  console.log("sfsfd")
  history.push("/categories/"+res+"/all-products")
    
}




    
const setDisplayMenu3 =() =>{
  if(document.getElementById("menu-mobile-1").style.visibility === "hidden"){
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "visible"
    document.getElementById("menu-mobile-2").style.visibility = "visible"
  }
  else{
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "hidden"
    document.getElementById("menu-mobile-2").style.visibility = "hidden"
  }
}
        return (
            <>
             { DisplaySearch ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      :""
}  
<header className="header header-intro-clearance header-26">
              <div className="header-top">
                <div>
                  <div style={{background  : "black"}}>
                    {/* <marquee behavior="" direction=""> */}
                   <center>
                    <a  className="font-weight-normal">
                     <b style={{color : "white"}}>Welcome to Falcon Rides Rent a Car, your premier choice for Car Rental Services in Dubai.
                      </b> 
                      </a>
                   </center>
                    
                    {/* </marquee> */}
                  </div>
                
                  {/* End .header-right */}
                </div>
                {/* End .container */}
              </div>
              {/* End .header-top */}
              <div className="header-middle">
                <div className="container">
                  <div className="header-left">
                    <button className="mobile-menu-toggler" onClick={()=>setDisplayMenu3()}>
                      <span className="sr-only">Toggle mobile menu</span>
                      <i className="icon-bars" />
                    </button>
                    <Link to="/" className="logo">
                      <img src={logo1} alt="Molla Logo" width={105} height={25} style={{width: "248px",
    height: "129px",
    objectFit: "contain"}}/>
                    </Link>
                  </div>
                  {/* End .header-left */}
                  <div className="header-center">
                    <div className="header-search header-search-visible header-search-no-radius">
                      <a href="#" className="search-toggle" role="button">
                        <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                      </a>
                      <form onSubmit={(e)=>SubmitData45342(e)}>
                        <div className="header-search-wrapper search-wrapper-wide">
                          
                          <label htmlFor="q" className="sr-only">Search</label>
                          <input type="search" className="form-control" name="q" id="q" required value={search} onChange={(e)=>setSearch(e.target.value)}  style={{ }} placeholder="Search for Cars , brands , Model No...."/>
                          <button className="btn btn-primary" type="submit">
                            <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                          </button>
                        </div>
                        {/* End .header-search-wrapper */}
                      </form>
                    </div>
                    {/* End .header-search */}
                  </div>
                  <div className="header-right">
                    <div className="header-dropdown-link">
                      
                      <div className="wishlist">
                        <Link to="/whislist" title="Wishlist">
                          <div className="icon">
                            <i className="icon-heart-o" />
                            <span className="wishlist-count badge">{ fav1 ? fav1.length : 0}</span>
                          </div>
                          <p>Wishlist</p>
                        </Link>
                      </div>
                      {/* End .compare-dropdown */}
                      <div className="dropdown cart-dropdown">
                        <Link to="/cart" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                          <div className="icon">
                            <i className="icon-shopping-cart" />
                            <span className="cart-count">{ cart1 ? cart1.length :0}</span>
                          </div>
                          <p>Cart</p>
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <div className="dropdown-cart-products">


                          {
                          cart1.length >= 1 ? cart1.map((item,i)=>{
                            return(
                                <div className="product">
                                  <div className="product-cart-details">
                                    <h4 className="product-title letter-spacing-normal font-size-normal">
                                      <Link to={"/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id}>{item.Product_Name}</Link>
                                    </h4>
                                    <span className="cart-product-info">
                                      <span className="cart-product-qty">{item.Pieces}</span>
                                      x ${(item.Total_Product_Price /  item.Pieces ).toFixed(0)}
                                    </span>
                                  </div>
                                  {/* End .product-cart-details */}
                                  <figure className="product-image-container">
                                    <Link to={"/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id} >
                                      <img src={item.Product_Image_Upload} alt="Product image"  onClick={()=>props.history.push("/categories/"+item.Product_Catagories+"/"+item.Product_Sub_Catagories+"/"+item.Course_Sub_Catagories+"/all-products/"+item.Product_Name+"/"+item._id)} width={200} height={300} />
                                    </Link>
                                  </figure>
                                  {/* <a href="#" className="btn-remove" title="Remove Product">
                                    <i className="icon-close" />
                                  </a> */}
                              </div>

                              )
                            })
                            : <span style={{textAlign : "center"}}>Cart Empty</span>
                          }
    

                            
                          </div>
                          {/* End .cart-product */}
                          {/* <div className="dropdown-cart-total">
                            <span>Total</span>
                            <span className="cart-total-price">$
                            {(total1).toFixed(0)

} 
                            </span>
                          </div> */}
                          {/* End .dropdown-cart-total */}
                          <div className="dropdown-cart-action">
                            <Link to="/cart" className="btn btn-outline-primary-2">View Cart</Link>
                            <Link to="/checkout" className="btn btn-outline-primary-2">
                              <span>Checkout</span>
                              <i className="icon-long-arrow-right" />
                            </Link>
                          </div>
                          {/* End .dropdown-cart-total */}
                        </div>
                        {/* End .dropdown-menu */}
                      </div>
                      {/* End .cart-dropdown */}
                    </div>
                  </div>
                  
                  {/* End .header-right */}
                </div>
                
                {/* End .container */}
              </div>

              {/* End .header-middle */}









              <div className="header-top">
                <div className="container">
                <form onSubmit={(e)=>SubmitData45342(e)} className="mobile-search mobile-search1">
                <label htmlFor="mobile-search" className="sr-only">Search</label>
                <input type="search" name="mobile-search" id="mobile-search" required value={search} onChange={(e)=>setSearch(e.target.value)} style={{width: "100%",color : "black",background : "white" , border : " none"}} placeholder="Search for Cars , brands , Model No...."/>
                <button className="btn btn-primary" type="submit">
                  <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                </button>
              </form>
                  
                  {/* End .header-right */}
                </div>
                {/* End .container */}
              </div>

























              {/* <div className="header-bottom sticky-header">
                <div className="container">
                  <div className="header-center">
                    <nav className="main-nav">
                      <ul className="menu sf-arrows">
                        <li className="megamenu-container active">
                          <Link to="/">Home</Link>
                          
                        </li>
                        <li>
                          <a href="category.html" className="sf-with-ul">Shop</a>
                          <div className="megamenu megamenu-md">
                            <div className="row no-gutters">
                              <div className="col-md-8">
                                <div className="menu-col">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="menu-title">Shop with sidebar</div>
                                      <ul>
                                        <li>
                                          <a href="category-list.html">Shop List</a>
                                        </li>
                                        <li>
                                          <a href="category-2cols.html">Shop Grid 2 Columns</a>
                                        </li>
                                        <li>
                                          <a href="category.html">Shop Grid 3 Columns</a>
                                        </li>
                                        <li>
                                          <a href="category-4cols.html">Shop Grid 4 Columns</a>
                                        </li>
                                        <li>
                                          <a href="category-market.html">
                                            <span>Shop Market
                                              <span className="tip tip-new">New</span>
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="menu-title">Shop no sidebar</div>
                                      <ul>
                                        <li>
                                          <a href="category-boxed.html">
                                            <span>Shop Boxed No Sidebar
                                              <span className="tip tip-hot">Hot</span>
                                            </span>
                                          </a>
                                        </li>
                                        <li>
                                          <a href="category-fullwidth.html">Shop Fullwidth No Sidebar</a>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="menu-title">Product Category</div>
                                      <ul>
                                        <li>
                                          <a href="product-category-boxed.html">Product Category Boxed</a>
                                        </li>
                                        <li>
                                          <a href="product-category-fullwidth.html">
                                            <span>Product Category Fullwidth
                                              <span className="tip tip-new">New</span>
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                      <div className="menu-title">Shop Pages</div>
                                      <ul>
                                        <li>
                                          <a href="cart.html">Cart</a>
                                        </li>
                                        <li>
                                          <a href="checkout.html">Checkout</a>
                                        </li>
                                        <li>
                                          <a href="wishlist.html">Wishlist</a>
                                        </li>
                                        <li>
                                          <a href="dashboard.html">My Account</a>
                                        </li>
                                        <li>
                                          <a href="#">Lookbook</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="banner banner-overlay">
                                  <a href="category.html" className="banner banner-menu">
                                    <img src="assets/images/menu/banner-1.jpg" alt="Banner" width={218} height={314} />
                                    <div className="banner-content banner-content-top">
                                      <div className="banner-title text-white">Last
                                        <br />Chance
                                        <br />
                                        <span>
                                          <strong>Sale</strong>
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="product.html" className="sf-with-ul">Product</a>
                          <div className="megamenu megamenu-sm">
                            <div className="row no-gutters">
                              <div className="col-md-6">
                                <div className="menu-col">
                                  <div className="menu-title">Product Details</div>
                                  <ul>
                                    <li>
                                      <a href="product.html">Default</a>
                                    </li>
                                    <li>
                                      <a href="product-centered.html">Centered</a>
                                    </li>
                                    <li>
                                      <a href="product-extended.html">
                                        <span>Extended Info
                                          <span className="tip tip-new">New</span>
                                        </span>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="product-gallery.html">Gallery</a>
                                    </li>
                                    <li>
                                      <a href="product-sticky.html">Sticky Info</a>
                                    </li>
                                    <li>
                                      <a href="product-sidebar.html">Boxed With Sidebar</a>
                                    </li>
                                    <li>
                                      <a href="product-fullwidth.html">Full Width</a>
                                    </li>
                                    <li>
                                      <a href="product-masonry.html">Masonry Sticky Info</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="banner banner-overlay">
                                  <a href="category.html">
                                    <img src="assets/images/menu/banner-2.jpg" alt="Banner" width={218} height={310} />
                                    <div className="banner-content banner-content-bottom">
                                      <div className="banner-title text-white">New Trends
                                        <br />
                                        <span>
                                          <strong>spring 2019</strong>
                                        </span>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="#" className="sf-with-ul">Pages</a>
                          <ul>
                            <li>
                              <a href="about.html" className="sf-with-ul">About</a>
                              <ul>
                                <li>
                                  <a href="about.html">About 01</a>
                                </li>
                                <li>
                                  <a href="about-2.html">About 02</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="contact.html" className="sf-with-ul">Contact</a>
                              <ul>
                                <li>
                                  <a href="contact.html">Contact 01</a>
                                </li>
                                <li>
                                  <a href="contact-2.html">Contact 02</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="login.html">Login</a>
                            </li>
                            <li>
                              <a href="faq.html">FAQs</a>
                            </li>
                            <li>
                              <a href="404.html">Error 404</a>
                            </li>
                            <li>
                              <a href="coming-soon.html">Coming Soon</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="blog.html" className="sf-with-ul">Blog</a>
                          <ul>
                            <li>
                              <a href="blog.html">Classic</a>
                            </li>
                            <li>
                              <a href="blog-listing.html">Listing</a>
                            </li>
                            <li>
                              <a href="#">Grid</a>
                              <ul>
                                <li>
                                  <a href="blog-grid-2cols.html">Grid 2 columns</a>
                                </li>
                                <li>
                                  <a href="blog-grid-3cols.html">Grid 3 columns</a>
                                </li>
                                <li>
                                  <a href="blog-grid-4cols.html">Grid 4 columns</a>
                                </li>
                                <li>
                                  <a href="blog-grid-sidebar.html">Grid sidebar</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Masonry</a>
                              <ul>
                                <li>
                                  <a href="blog-masonry-2cols.html">Masonry 2 columns</a>
                                </li>
                                <li>
                                  <a href="blog-masonry-3cols.html">Masonry 3 columns</a>
                                </li>
                                <li>
                                  <a href="blog-masonry-4cols.html">Masonry 4 columns</a>
                                </li>
                                <li>
                                  <a href="blog-masonry-sidebar.html">Masonry sidebar</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Mask</a>
                              <ul>
                                <li>
                                  <a href="blog-mask-grid.html">Blog mask grid</a>
                                </li>
                                <li>
                                  <a href="blog-mask-masonry.html">Blog mask masonry</a>
                                </li>
                              </ul>
                            </li>
                            <li>
                              <a href="#">Single Post</a>
                              <ul>
                                <li>
                                  <a href="single.html">Default with sidebar</a>
                                </li>
                                <li>
                                  <a href="single-fullwidth.html">Fullwidth no sidebar</a>
                                </li>
                                <li>
                                  <a href="single-fullwidth-sidebar.html">Fullwidth with sidebar</a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="elements-list.html" className="sf-with-ul">Elements</a>
                          <ul>
                            <li>
                              <a href="elements-products.html">Products</a>
                            </li>
                            <li>
                              <a href="elements-typography.html">Typography</a>
                            </li>
                            <li>
                              <a href="elements-titles.html">Titles</a>
                            </li>
                            <li>
                              <a href="elements-banners.html">Banners</a>
                            </li>
                            <li>
                              <a href="elements-product-category.html">Product Category</a>
                            </li>
                            <li>
                              <a href="elements-video-banners.html">Video Banners</a>
                            </li>
                            <li>
                              <a href="elements-buttons.html">Buttons</a>
                            </li>
                            <li>
                              <a href="elements-accordions.html">Accordions</a>
                            </li>
                            <li>
                              <a href="elements-tabs.html">Tabs</a>
                            </li>
                            <li>
                              <a href="elements-testimonials.html">Testimonials</a>
                            </li>
                            <li>
                              <a href="elements-blog-posts.html">Blog Posts</a>
                            </li>
                            <li>
                              <a href="elements-portfolio.html">Portfolio</a>
                            </li>
                            <li>
                              <a href="elements-cta.html">Call to Action</a>
                            </li>
                            <li>
                              <a href="elements-icon-boxes.html">Icon Boxes</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                    <i className="la la-lightbulb-o" />
                    <p className="text-dark">Clearance Up to 30% Off</p>
                  </div> 
                </div>
              </div> */}


























              <div className="mobile-menu-overlay" />
          <div className="mobile-menu-container" id="menu-mobile-1">
            <div className="mobile-menu-wrapper">
              <span className="mobile-menu-close"  onClick={()=>setDisplayMenu3()}>
                <i className="icon-close" />
              </span>
              {/* <form onSubmit={(e)=>SubmitData4534(e)} className="mobile-search">
                <label htmlFor="mobile-search" className="sr-only">Search</label>
                <input type="search" className="form-control" name="mobile-search" id="mobile-search" required value={search} onChange={(e)=>setSearch(e.target.value)} style={{ }} placeholder="Search for Cars , brands , Model No...."/>
                <button className="btn btn-primary" type="submit">
                  <i className="icon-search" style={{transform: "rotate(280deg)"}} />
                </button>
              </form> */}
              <ul className="nav nav-pills-mobile" role="tablist">
                <li className="nav-item">
                  <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase active" id="mobile-cats-link" data-toggle="tab" href="#mobile-cats-tab" role="tab" aria-controls="mobile-cats-tab" aria-selected="false">Categories</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link font-size-normal second-primary-color font-weight-normal text-uppercase" id="mobile-menu-link" data-toggle="tab" href="#mobile-menu-tab" role="tab" aria-controls="mobile-menu-tab" aria-selected="true">Menu</a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade" id="mobile-menu-tab" role="tabpanel" aria-labelledby="mobile-menu-link">
                  <nav className="mobile-nav">
                    <ul className="mobile-menu">
                      <li className="active">
                        <Link onClick={()=>setDisplayMenu3()} to="/">Home</Link>
                      </li>
                      <li>
                        <a >Shop <span class="mmenu-btn"></span></a>
                        <ul>
                          
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/shop-all">Shop All</Link>
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/new-arrival">Today's Best</Link>
                          </li>
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/expert-pick">
                              <span>Trending
                                <span className="tip tip-hot">Hot</span>
                              </span>
                            </Link>
                          </li> */}
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/recommended-product">Features Product</Link>
                          </li>
                         {/*  <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/product/recommended-product">
                              <span>Recommended Product
                                <span className="tip tip-new">New</span>
                              </span>
                            </Link>
                          </li> */}
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/cart">Cart</Link>
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/checkout">Checkout</Link>
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/whislist">Wishlist</Link>
                          </li>
                          {/* <li>
                            <a href="#">Lookbook</a>
                          </li> */}
                        </ul>
                      </li>
                      {/* <li>
                        <a href="product.html" className="sf-with-ul">Product</a>
                        <ul>
                          <li>
                            <a href="product.html">Default</a>
                          </li>
                          <li>
                            <a href="product-centered.html">Centered</a>
                          </li>
                          <li>
                            <a href="product-extended.html">
                              <span>Extended Info
                                <span className="tip tip-new">New</span>
                              </span>
                            </a>
                          </li>
                          <li>
                            <a href="product-gallery.html">Gallery</a>
                          </li>
                          <li>
                            <a href="product-sticky.html">Sticky Info</a>
                          </li>
                          <li>
                            <a href="product-sidebar.html">Boxed With Sidebar</a>
                          </li>
                          <li>
                            <a href="product-fullwidth.html">Full Width</a>
                          </li>
                          <li>
                            <a href="product-masonry.html">Masonry Sticky Info</a>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <a>Pages <span class="mmenu-btn"></span></a>
                        <ul>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/about">About</Link>
                            {/* <ul>
                              <li>
                                <Link to="/Linkbout">About 01</Link>
                              </li>
                              <li>
                                <Link to="/Linkbout-2">About 02</Link>
                              </li>
                            </ul> */}
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/contact">Contact</Link>
                            {/* <ul>
                              <li>
                                <Link to="/contact">Contact 01</Link>
                              </li>
                              <li>
                                <Link to="/contact-2">Contact 02</Link>
                              </li>
                            </ul> */}
                          </li>
                          {/* <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/login">Login</Link>
                          </li> */}
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="/help">FAQs</Link>
                          </li>
                          <li>
                            <Link onClick={()=>setDisplayMenu3()}  to="*">Error 404</Link>
                          </li>
                         
                        </ul>
                      </li>
                    
                    </ul>
                  </nav>
                  {/* End .mobile-nav */}
                </div>
                {/* .End .tab-pane */}
                <div className="tab-pane fade show active" id="mobile-cats-tab" role="tabpanel" aria-labelledby="mobile-cats-link">
                  <nav className="mobile-cats-nav">
                    <ul className="mobile-cats-menu">
                    {MainCatogories.map((res,i)=>{
                         if(res.MainCategories === "One Way Drop"){
                          return (
                         <>
                
                        <li>
                        <a className="mobile-cats-lead" href={"/main-categories-car-rental/"+res.MainCategories }>{res.MainCategories}</a>
                      </li>
                       </>
                    )
                  }
                  else{
                    return (
                   <>
<li>
                        <a className="mobile-cats-lead" href={"/main-categories/"+res.MainCategories }>{res.MainCategories}</a>
                      </li>
                       </>


                     )
                   }
                            
                            
                   
                  
                  })
                

                              }

 {/* <li>
                        <Link className="mobile-cats-lead" to="/tourism">Tourism</Link>
                      </li> */}
                      <li>
                        <Link className="mobile-cats-lead" href={"/Corporate-Equiries"}>Corporate Equiries</Link>
                      </li>
                      <li>
                        <Link className="mobile-cats-lead" href={"/Contact"}> Contact Us</Link>
                      </li>
                      <li>
                        <Link className="mobile-cats-lead" href={"/About"}>About Us</Link>
                      </li>
                      {/* <li>
                        <a className="mobile-cats-lead" href="#">Daily offers</a>
                      </li>
                      <li>
                        <a className="mobile-cats-lead" href="#">Gift Ideas</a>
                      </li> */}
                     
                     
                     
                     
                     
                      {/* <li>
                        <a href="#">Beds</a>
                      </li>
                      <li>
                        <a href="#">Lighting</a>
                      </li>
                      <li>
                        <a href="#">Sofas &amp;Sleeper sofas</a>
                      </li>
                      <li>
                        <a href="#">Storage</a>
                      </li>
                      <li>
                        <a href="#">Armchairs &amp;Chaises</a>
                      </li>
                      <li>
                        <a href="#">Decoration </a>
                      </li>
                      <li>
                        <a href="#">Kitchen Cabinets</a>
                      </li>
                      <li>
                        <a href="#">Coffee &amp;Tables</a>
                      </li>
                      <li>
                        <a href="#">Outdoor Furniture </a>
                      </li> */}
                    </ul>
                    {/* End .mobile-cats-menu */}
                  </nav>
                  {/* End .mobile-cats-nav */}
                </div>
                {/* .End .tab-pane */}
              </div>
              {/* End .tab-content */}
              <div className="social-icons justify-content-center">
                <a href="https://www.facebook.com/profile.php?id=100079612164972" className="social-icon" target="_blank" title="Facebook">
                  <i className="icon-facebook-f" />
                </a>
                {/* <a href="#" className="social-icon" target="_blank" title="Twitter">
                  <i className="icon-twitter" />
                </a> */}
                {/* <a href="https://www.instagram.com/rentacarspd/" className="social-icon" target="_blank" title="Instagram">
                  <i className="icon-instagram" />
                </a> */}
                <a href="https://www.youtube.com/@chandtoursrentacar8116" className="social-icon" target="_blank" title="Youtube">
                  <i className="icon-youtube" />
                </a>
              </div>
              {/* End .social-icons */}
            </div>
            {/* End .mobile-menu-wrapper */}
          </div>















          <div className="mobile-menu-container1" id="menu-mobile-2" onClick={()=>setDisplayMenu3()}>
            <div className="mobile-menu-wrapper1">
              {/* End .social-icons */}
            </div>
            {/* End .mobile-menu-wrapper */}
          </div>













          {/* End .mobile-menu-container */}
          {/* Sign in / Register Modal */}
          <div className="modal fade" id="signin-modal" tabIndex={-1} role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">
                      <i className="icon-close" />
                    </span>
                  </button>
                  <div className="form-box">
                    <div className="form-tab">
                      <ul className="nav nav-pills nav-fill" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color active" id="signin-tab" data-toggle="tab" href="#signin" role="tab" aria-controls="signin" aria-selected="true">Sign In</a>
                        </li>
                        <li className="nav-item">
                          <a className="nav-link font-size-normal second-primary-color" id="register-tab" data-toggle="tab" href="#register" role="tab" aria-controls="register" aria-selected="false">Register</a>
                        </li>
                      </ul>
                      <div className="tab-content" id="tab-content-5">
                        <div className="tab-pane fade show active" id="signin" role="tabpanel" aria-labelledby="signin-tab">
                          <form action="#">
                            <div className="form-group">
                              <label htmlFor="singin-email">Username or email address *</label>
                              <input type="text" className="form-control" id="singin-email" name="singin-email" required />
                            </div>
                            {/* End .form-group */}
                            <div className="form-group">
                              <label htmlFor="singin-password">Password *</label>
                              <input type="password" className="form-control" id="singin-password" name="singin-password" required />
                            </div>
                            {/* End .form-group */}
                            <div className="form-footer">
                              <button type="submit" className="btn btn-outline-primary-2">
                                <span>LOG IN</span>
                                <i className="icon-long-arrow-right" />
                              </button>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="signin-remember" />
                                <label className="custom-control-label" htmlFor="signin-remember">Remember Me</label>
                              </div>
                              {/* End .custom-checkbox */}
                              <a href="#" className="forgot-link">Forgot Your Password?</a>
                            </div>
                            {/* End .form-footer */}
                          </form>
                          <div className="form-choice">
                            <p className="text-center">or sign in with</p>
                            <div className="row">
                              <div className="col-sm-6">
                                <a href="#" className="btn btn-login btn-g">
                                  <i className="icon-google" />
                                  Login With Google
                                </a>
                              </div>
                              {/* End .col-6 */}
                              <div className="col-sm-6">
                                <a href="#" className="btn btn-login btn-f">
                                  <i className="icon-facebook-f" />
                                  Login With Facebook
                                </a>
                              </div>
                              {/* End .col-6 */}
                            </div>
                            {/* End .row */}
                          </div>
                          {/* End .form-choice */}
                        </div>
                        {/* .End .tab-pane */}
                        <div className="tab-pane fade" id="register" role="tabpanel" aria-labelledby="register-tab">
                          <form action="#">
                            <div className="form-group">
                              <label htmlFor="register-email">Your email address *</label>
                              <input type="email" className="form-control" id="register-email" name="register-email" required />
                            </div>
                            {/* End .form-group */}
                            <div className="form-group">
                              <label htmlFor="register-password">Password *</label>
                              <input type="password" className="form-control" id="register-password" name="register-password" required />
                            </div>
                            {/* End .form-group */}
                            <div className="form-footer">
                              <button type="submit" className="btn btn-outline-primary-2">
                                <span>SIGN UP</span>
                                <i className="icon-long-arrow-right" />
                              </button>
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="register-policy" required />
                                <label className="custom-control-label" htmlFor="register-policy">I agree to the
                                  <a href="#">privacy policy</a> *</label>
                              </div>
                              {/* End .custom-checkbox */}
                            </div>
                            {/* End .form-footer */}
                          </form>
                          <div className="form-choice">
                            <p className="text-center">or sign in with</p>
                            <div className="row">
                              <div className="col-sm-6">
                                <a href="#" className="btn btn-login btn-g">
                                  <i className="icon-google" />
                                  Login With Google
                                </a>
                              </div>
                              {/* End .col-6 */}
                              <div className="col-sm-6">
                                <a href="#" className="btn btn-login  btn-f">
                                  <i className="icon-facebook-f" />
                                  Login With Facebook
                                </a>
                              </div>
                              {/* End .col-6 */}
                            </div>
                            {/* End .row */}
                          </div>
                          {/* End .form-choice */}
                        </div>
                        {/* .End .tab-pane */}
                      </div>
                      {/* End .tab-content */}
                    </div>
                    {/* End .form-tab */}
                  </div>
                  {/* End .form-box */}
                </div>
                {/* End .modal-body */}
              </div>
              {/* End .modal-content */}
            </div>
            {/* End .modal-dialog */}
          </div>
          {/* End .modal */}
              {/* End .header-bottom */}
            </header>
        </>
        )
}

export default Header;