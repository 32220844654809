// import React, { useState , useEffect } from 'react';
// import {Link ,useHistory} from "react-router-dom"
// // import FacebookIcon from '@material-ui/icons/Facebook';
// // import InstagramIcon from '@material-ui/icons/Instagram';
// // import TwitterIcon from '@material-ui/icons/Twitter';
// // import YouTubeIcon from '@material-ui/icons/YouTube';
// // import LinkedInIcon from '@material-ui/icons/LinkedIn';
// // import SendIcon from '@material-ui/icons/Send';
// // import LocationOnIcon from '@material-ui/icons/LocationOn';
// // import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// // import { Flare } from '@material-ui/icons';
// // import KeyboardArrowRightSharpIcon from '@material-ui/icons/KeyboardArrowRightSharp';
import logo1 from '../assets/images/logo-3.png';
// import payment from '../asset1/images/payments.png';

// // import FacebookIcon from '@mui/icons-material/Facebook';
//   const Footer =(props)=> {
//     const [MainCatogories , setMainCategories] = useState([])
//     useEffect(() =>{

//     fetch("https://chand-rentacar-main-back.vercel.app/MainCatogories",{
//             method: "GET",
//              headers :  {
//              "Content-Type" : "application/json" , 
//          } ,
//         })
//         .then(res3=>res3.json())
//         .then(res4=>{
//             let dat = []
//             let dat1 = []
//             res4.map((res55,c)=>{
//               if(c < 10){
//                 dat.push(res55)
//                 dat1.push(0)

//               }
//             })
//                         console.log(dat)
//             let data = dat.sort((a, b) => {
//                 return a.sort - b.sort;
//             });
//             setMainCategories(data)

//         })
//       },[])

//   //  history = useHistory();
  
//         return (
//             <>
//           <footer className="footer footer-2 font-weight-normal" >
              
//               {/* End .cta */}
//               <div className="container">
//                 <hr className="mt-0 mb-0" style={{borderColor: '#444'}} />
//               </div>
//               <div className="footer-middle border-0">
//                 <div className="container">
//                   <div className="row">
//                     <div className="col-12 col-lg-2-5cols">
//                       <div className="widget widget-about mb-4">
//                         <img src={logo1} className="footer-logo" alt="Footer Logo" width={105} height={25} style={{height: "103px",
//     width: "229px",
//     objectFit: "cover"}}/>
//                         <p className="font-weight-normal" style={{color : "black"}}>Introducing Pakistan's first on-demand car rental marketplace, Chand Rent A Car! We're here to revolutionize the world of renting with a more convenient and modern approach. Now, renting a car is just a click away, available online for a seamless customer experience.


//                          </p>
//                         <div className="widget-about-info">
//                           <div className="row">
//                             <div className="col-sm-6 col-md-6">
//                               <span className="widget-about-title" style={{fontWeight : 600,fontSize : "16px" , color : "black"}}>Got Question? Call us 24/7</span>
//                               <a href="tel:+923228487555" style={{fontWeight : 100,color : "black"}}>+92 322 8487555</a> <br />
//                             </div>
                        
//                             <div className="col-sm-6 col-md-6">
//                               <span className="widget-about-title" style={{fontWeight : 600,fontSize : "16px" , color : "black"}}>Any Query</span>
//                               <a href="mailto:info@chandtourrentacar.com" style={{fontWeight : 100,color : "black"}}>info@chandtourrentacar.com</a> <br />

//                             </div>
                      
                           
                            
//                             {/* End .col-sm-6 */}
//                           </div>
//                           {/* End .row */}
//                         </div>
//                         {/* End .widget-about-info */}
//                       </div>
//                       {/* End .widget about-widget */}
//                     </div>
//                     {/* End .col-sm-12 col-lg-3 */}
//                     <div className="col-sm-3 col-lg-5cols">
//                       <div className="widget mb-4">
//                         <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "black"}}>Information</h4>
//                         {/* End .widget-title */}
//                         <ul className="widget-list" style={{color : "black"}}>
//                           <li>
//                             <Link to="/about">About Us</Link>
//                           </li>
//                           {/* <li>
//                             <Link to="/tourism">Tourism</Link>
//                           </li> */}
//                            {/* <li>
//                             <Link to="/">Returns</Link>
//                           </li> */}
//                           {/* <li>
//                             <Link to="/Shipping">Shipping</Link>
//                           </li> */}
//                           <li>
//                             <Link to="/term-policy">Terms and conditions</Link>
//                           </li>
//                           <li>
//                             <Link to="/privacy">Privacy Policy</Link>
//                           </li>
//                           {/* <li>
//                             <Link to="/">How to shop on Kart Budget</Link>
//                           </li> */}
//                           {/* <li>
//                             <Link to="/help">FAQ</Link>
//                           </li> */}
//                           <li>
//                             <Link to="/contact">Contact us</Link>
//                           </li>
//                           {/* <li>
//                             <Link to="/login">Log in</Link>
//                           </li> */}
//                         </ul>
//                         {/* End .widget-list */}
//                       </div>
//                       {/* End .widget */}
//                     </div>
//                     {/* End .col-sm-4 col-lg-3 */}
                  
//                     {/* End .col-sm-4 col-lg-3 */}
//                     <div className="col-sm-3 col-lg-5cols">
//                       <div className="widget mb-4">
//                         <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "black"}}>Customer Service</h4>
//                         {/* <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "black"}}>My Account</h4> */}
//                         {/* End .widget-title */}
//                         <ul className="widget-list" style={{color : "black"}}>
//                           {/* <li>
//                             <Link to="/login">Sign In</Link>
//                           </li> */}
//                           <li>
//                             <Link to="/Corporate-Equiries">Corporate Enquiries</Link>
//                           </li>
//                           <li>
//                             <Link to="/cart">View Cart</Link>
//                           </li>
//                           <li>
//                             <Link to="/whislist">My Wishlist</Link>
//                           </li>
//                           {/* <li>
//                             <Link to="/track">Track My Order</Link>
//                           </li> */}
//                           <li>
//                             <Link to="/FAQ">Help</Link>
//                           </li>
//                         </ul>
//                         {/* End .widget-list */}
//                       </div>
//                       {/* End .widget */}
//                     </div>
//                     {/* End .col-sm-64 col-lg-3 */}

//   <div className="col-sm-3 col-lg-5cols">
//                       <div className="widget mb-4">
//                         <h4 className="widget-title" style={{fontWeight : 600,fontSize : "16px" , color : "black"}}>Category</h4>
//                         <ul className="widget-list" style={{color : "black"}}>
//                         {MainCatogories.map((res,i)=>{
//                     return (
//                       <>  
//                        <li>
//                             <a href={"/categories/"+res.MainCategories +"/all-products"}>{res.MainCategories}</a>
//                           </li>
//                            </>
                    
//                     )
                  
//                   })
                

//                               }
//                         </ul>
//                       </div>
//                     </div>


//                   </div>
//                   {/* End .row */}
//                 </div>
//                 {/* End .container */}
//               </div>
//               {/* End .footer-middle */}
//               <div className="footer-bottom font-weight-normal">
//                 <div className="container">
//                   <p className="font-weight-normal ml-lg-2" style={{fontSize : "13px", fontWeight :"500",color : "black"}}>© Copyright 2023. All rights reserved by Chand Tours & Rent a Car . Powered by                        <b><a target='_blank' href="https://www.webocreators.com">Webo Creators</a></b>  


// </p>
//                   {/* End .footer-copyright */}
//                   {/* <ul className="footer-menu justify-content-center">
//                     <li>
//                       <a href="#">Terms Of Use</a>
//                     </li>
//                     <li>
//                       <a href="#">Privacy Policy</a>
//                     </li>
//                   </ul> */}
//                   {/* End .footer-menu */}
//                   <div className="social-icons social-icons-color justify-content-center">
//                     <span className="social-label" style={{color : "black"}}>Social Media</span>
//                     <a href="https://www.facebook.com/profile.php?id=100079612164972" className="social-icon social-facebook" title="Facebook" target="_blank">
//                       <i className="icon-facebook-f" />
//                     </a>
//                     {/* <a href="#" className="social-icon social-twitter" title="Twitter" target="_blank">
//                       <i className="icon-twitter" />
//                     </a> */}
//                     {/* <a href="https://www.instagram.com/rentacarspd/" className="social-icon social-instagram" title="Instagram" target="_blank">
//                       <i className="icon-instagram" />
//                     </a> */}
//                     <a href="https://www.facebook.com/profile.php?id=100079612164972" className="social-icon social-youtube" title="Youtube" target="_blank">
//                       <i className="icon-youtube" />
//                     </a>
//                   </div>
//                   {/* End .soial-icons */}
//                 </div>
//                 {/* End .container */}
//               </div>
//               {/* End .footer-bottom */}
//             </footer>
            
         
//             </>
//         )
    
// }

// export default Footer;


import {Link ,useHistory} from "react-router-dom"

// import { IconMail, IconPhoneCall } from "@tabler/icons-react";

// import AttachEmailIcon from '@material-ui/icons/AttachEmail';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';


// import MarkEmailUnreadIcon from '@material-ui/icons/MarkEmailUnread';


function Footer() {
  return (
    <>
      <footer style={{marginTop:"20px"}}>
        <div className="container">
            <hr/>
          <div className="footer-content">
            <ul className="footer-content__1">
              <li>
                                   <img src={logo1} className="footer-logo iiioioiooi" alt="Footer Logo" width={105} height={25} style={{height: "103px",
     width: "229px",
    // objectFit: "cover"
    }}/>
              </li>
              <li>
              Experience seamless travel with <b> Rent a Car in Karachi</b>. Choose from our diverse fleet for a reliable and comfortable journey through the city.
              </li>
              <li>
                <a href="tel:+923182333057">
                  <PhoneCallbackIcon /> &nbsp; +92 318 2333057
                </a>
              </li>

              <li>
                <a
                  href="mailto:Karachirentacar903@gmail.com"
                >
                  {/* <MarkEmailUnreadIcon /> */}
                  <i className="icon-envelope" />

                  &nbsp;Karachirentacar903@gmail.com
                </a>
              </li>

             
            </ul>

            <ul className="footer-content__2">
              <li>Quick Links</li>
              <li>
                             <Link to="/categories/Cars/all-products">Car Collection</Link>
                           </li>
              <li>
                             <Link to="/about">About Us</Link>
                           </li>
                           <li>
                             <Link to="/contact">Contact us</Link>
                           </li>
                             <li>
                             <Link to="/cart">View Cart</Link>
                           </li>
              <li>
                             <Link to="/privacy">Rental Requirement</Link>
                           </li>
              {/* <li>
                             <Link to="/traffic-fines">Traffic Fines</Link>
                           </li> */}
                           <li>
                             <Link to="/term-policy">Terms & conditions</Link>
                           </li>
                           {/* <li>
                             <Link to="/privacy">Privacy Policy</Link>
                           </li> */}
                         
            </ul>

            <ul className="footer-content__2" >
            {/* <ul className="footer-content__2" style={{width:"max-content",textAlign : "center"}}> */}
              <li>Services Areas</li>
              <li>
                             <Link to="/rent-a-car-in-karachi">Rent a Car in Karachi</Link>
                           </li>
              <li>
                             <Link to="/rent-a-car-karachi">Rent a Car Karachi</Link>
                           </li>
              <li>
                             <Link to="/rent-a-car-service-in-karachi">Rent a Car Service in Karachi</Link>
                           </li>
              <li>
                             <Link to="/car-rental-in-karachi">Car Rental in Karachi</Link>
                           </li>
              <li>
                             <Link to="/car-rental-karachi">Car Rental Karachi</Link>
                           </li>
          
             
             
             
            </ul> 
                        <ul>

            <ul className="footer-content__2">
              <li>Office Hours</li>
              <li>Mon - Fri: 8:00AM - 01:00AM</li>
              <li>Sat: 9:00AM - 12:00PM</li>
              <li>Sun: 24/7 </li>
            </ul>
            {/* <ul className="footer-content__2">
              <li>Subscription</li>
              <li>
                <p>Subscribe your Email address for latest news & updates.</p>
              </li>
              <li>
                <input type="email" placeholder="Enter Email Address"></input>
              </li>
              <li>
                <button className="submit-email">Submit</button>
              </li>
            </ul> */}
                        </ul>
          </div>
          <div>
            <center>

           <p>© Copyright 2024 | Rent a Car in Karachi | Pappu Prado | All Rights Reserved.  <a
                  style={{ fontSize: "14px" ,color  :"#96040e"}}
                  target="_blank"
                  rel="noreferrer"
                  href="https://webocreators.com/"
                >
                  Design by Webo Creators
                </a> </p>
            </center>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
